import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';
import VueMoment from 'vue-moment'
import moment from 'moment-timezone'
import firebase from "firebase";
import mwtr from "@/assets/translations.js"
import mwsettings from "@/assets/settings.js"
import mwutilities from "@/assets/utilities.js"
import mwsurveyutilities from "@/assets/surveyutilities.js"
import mwstylesettings from "@/assets/stylesettings.js"
import IdleVue from "idle-vue";

Vue.config.productionTip = false

// import * as Sentry from '@sentry/browser';
// import { Vue as VueIntegration } from '@sentry/integrations';


// Sentry.init({
//     dsn: 'https://8712f3b12cf743ed893eb91c382f2614@o397452.ingest.sentry.io/5667087',
//     integrations: [new VueIntegration({Vue, attachProps: true})],
//   });
  
  
  Vue.prototype.mwtr = mwtr;
  Vue.prototype.mwsettings = mwsettings;
  Vue.prototype.mwstylesettings = mwstylesettings;
  Vue.prototype.mwutilities = mwutilities;
  Vue.prototype.mwsurveyutilities = mwsurveyutilities;
  Vue.use(VueMoment, {
    moment,
  })
  
  const firebaseConfig = {
    apiKey: "AIzaSyApDHionvAp-UBZuQNOYgXdd4cJzHKHlFM",
    authDomain: "inngo-dev.firebaseapp.com",
    projectId: "inngo-dev",
    storageBucket: "inngo-dev.appspot.com",
    messagingSenderId: "416457741502",
    appId: "1:416457741502:web:3dafc95b977df34639baef",
    measurementId: "G-GVZLBGDBT1"
  };
  
  firebase.initializeApp(firebaseConfig);
  
  Vue.use(IdleVue, {
    store,
    idleTime: 1200000
  })

  new Vue({
    router,
    store,
    vuetify,
    render: h => h(App)
  }).$mount('#app')
