<template>
  <v-app>
    <MainNavigation @toggleSecondaryNav="secondaryNav = !secondaryNav" @toggleBottomNav="bottomNav = !bottomNav">
    </MainNavigation>
    <v-navigation-drawer v-model="secondaryNav" absolute :permanent="showSidebar" stateless :width="sidebarWidth">
      <SecondaryNavigation></SecondaryNavigation>
     <!--  <template v-slot:append v-if="currentOrganisation">
        <OrganisationSwitcher></OrganisationSwitcher>
      </template> -->
    </v-navigation-drawer>
    <v-main :style="sideBarStyle">
        <v-container class="pa-4 pl-7">
          <router-view></router-view>
        </v-container>
    </v-main>
    <Chat></Chat>
    <BottomNavigation :show="bottomNav" @hidden="bottomNav = false"></BottomNavigation>
  </v-app>
</template>

<style lang="less">
  @import "./assets/css/main";
</style>


<script>
import Vuex from "vuex";
// import OrganisationSwitcher from "@/components/navigation/OrganisationSwitcher"
import MainNavigation from "@/components/navigation/MainNavigation"
import SecondaryNavigation from "@/components/navigation/SecondaryNavigation"
import BottomNavigation from "@/components/navigation/BottomNavigation"
import Chat from "@/components/Chat"
export default {
  name: 'App',
  data: () => {
    return {
      bottomNav: false, 
      secondaryNav: false,
      sidebarWidth: 490
    }
  },
  components: {
    Chat,
    MainNavigation, 
    SecondaryNavigation,
    BottomNavigation,
    // OrganisationSwitcher
  },
  computed: {
    ...Vuex.mapState({
      state: state => state,
      view: state => state.auth.view,
      user: state => state.user,
      subs: state => state.subscriptions,
      organisation: state => state.organisation,
      assessment: state => state.assessment,
    }), 
    currentOrganisation(){
      return this.$store.getters.currentOrganisation;
    },
    currentAssessment(){
      return this.$store.getters.currentAssessment;
    },
    isMobile(){
      return this.$vuetify.breakpoint.xs
    },
    hasSidebar(){
       return this.$route.meta.hasSidebar
    },
    showSidebar(){
      if( !this.hasSidebar ){
        return false;
      }
      return !this.isMobile;
    },
    sideBarStyle() {
      if( this.showSidebar ){
        return {
          'margin-left': `${this.sidebarWidth}px`
        }
      }
      else{
        return {};
      }
    } 
  },
  beforeMount(){
      this.$store.dispatch("auth/check");
  },
  watch: {
    hasSidebar(value){
      if(!value){
        this.secondaryNav = false
      }
    },
    currentOrganisation(value){
      if( value ){
        this.$store.dispatch("organisation/start", value )
      }
    },
    currentAssessment(value){
      const self = this;
      if( value ){
        self.$store.dispatch("assessment/start", {
          id: value,
          config: self.organisation.assessmentConfig
        } )
      }
    }
  },
};
</script>
