export default {
	SmallDialogWidth: "300px",
	MediumDialogWidth: "500px", 
	Colors: [
		"green",
		"red", 
		"blue",
		"yellow", 
		"orange",
		"purple",
		"teal" 
	]
}