<template>
	<div>	
		<v-list>
			<v-list-item dense v-for="(link, i) in links" :key="i" router-link :to="link.path">
				<v-list-item-content>
					<v-list-item-title>{{mwtr[link.name]}}</v-list-item-title>
					<v-list-item-subtitle>Context</v-list-item-subtitle>
				</v-list-item-content>
			</v-list-item>
		</v-list>
	</div>
</template>

<style lang="less">
	
</style>

<script type="text/javascript">
	export default {
		name: "AdminNavigation", 
		props: {
			
		},
		data: () => {
			return {
				links: [
					{
						name: "UsersNavItem",
						path: "/admin/users"
					},
					{
						name: "OrganisationsNavItem",
						path: "/admin/organisations"
					},
					{
						name: "DepartmentsNavItem",
						path: "/admin/departments"
					},
					{
						name: "SectionsNavItem",
						path: "/admin/sections"
					},
					{
						name: "TagsNavItem",
						path: "/admin/tags"
					},
					{
						name: "QuestionsNavItem",
						path: "/admin/questions"
					},
					{
						name: "GoalNavItem",
						path: "/admin/goals"
					},
					{
						name: "GoalGroupsNavItem",
						path: "/admin/goal-groups"
					}
				]
			}
		},
		components: {
		},
		computed: {
			current(){
				return this.$route
			}
			// ...Vuex.mapState({})
		},
		// methods: {
		// }
		// created(){
			
		// }

	}
// </script>"