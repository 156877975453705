import Vue from "vue";
import firebase from "firebase";
const state = {};

const actions = {
  fetchAll({ dispatch}){
    dispatch("fetchAll", "sections", {root: true })
  }, 
  fetchOne({dispatch}, id){
    dispatch("fetchOne", {collection: "sections", id: id }, {root: true})
  }, 
  new({dispatch}, data ){
    return dispatch("new", {collection: "sections", data: data }, {root: true})
  },
  fetchParts({commit}, section ){
    if( !section ){
      return;
    }
    firebase.firestore().collection("sections/" + section + "/parts").where("status", "==", "published").get().then( snap => {
      snap.forEach( doc => {
        var data = doc.data()
        commit("SET_PART", {section: section, id: doc.id,  data })
      } );
    })
  },
  setPart({commit, dispatch}, {section, id, data } ){
    dispatch("updateDoc", {
      data: data, 
      doc: "sections/" + section + "/parts/" + id 
    }, {root: true} )
    commit( "SET_PART", {section, id, data })
  },
  addPart({commit, dispatch}, {section, data } ){
    dispatch("addDoc", {
      data: data, 
      collection: "sections/" + section + "/parts/" 
    }, {root: true} ).then( ref => {
      commit( "SET_PART", {section, id: ref, data })
    })
  }, 
  newWithParts( _, data){
    console.log( data )
  },
  deletePart({commit}, {section, part}){
    firebase.firestore().doc(`sections/${section}/parts/${part}`).update({status: "deleted"}).then(() => {
      commit( "REMOVE_PART", { section, part })
    })
  }
};

const mutations = {
  SET_PART( state,  {section, id, data} ){
    if( !state[section].parts ){
      Vue.set(state[section], "parts", {} )
    }
    Vue.set(state[section].parts, id, data )
  },
  ADD_GOAL( state, {section, part, goal }){
    if( !state[section].parts[part].goals ){
      Vue.set( state[section].parts[part], "goals", [goal] )
    }
    else{
      var goals = state[section].parts[part].goals;
      goals.push( goal ) 
    }
  },
  REMOVE_PART( state,  {section, part} ){
    Vue.delete(state[section].parts, part )
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
