import Vue from "vue";
import firebase from "firebase";
const state = {};

const actions = {
  get( state , id ){
    return new Promise( (resolve) => {
      if( state[id] ){
        resolve(state[id]);
      }
      else{
        firebase.firestore().doc(`collaborations/${id}`).get().then( doc => {
          var data = doc.data();
          resolve( data )
        })
      }
    })
  },
  fetchAllInAssessment({commit}, assessment ){
    firebase.firestore().collection("collaborations").where("assessment", "==", assessment ).get().then( snap => {
      snap.forEach( d => {
        commit("SET", {id: d.id, data: d.data() })
      })
    })
  },
  fetchOne({dispatch}, id){
    dispatch("fetchOne", {collection: "collaborations", id: id }, {root: true})
  }, 
  delegate({dispatch, rootState, commit}, id ){
    return new Promise( (resolve, reject ) => {
      var collab = {
        organisation: rootState.assessment.organisation,
        assessment: rootState.assessment.id,
        section: id, 
        type: "delegate"
      }
      dispatch("addDoc", {
        collection: "collaborations", 
        data: collab
      }, {root: true } ).then( collabId => {    
        dispatch("updateDoc", {
          doc: `assessments/${rootState.assessment.id}/sections/${id}`,
          data: {status: "delegated", collaboration: collabId}
        }, { root: true } )
        commit("assessment/SET_SECTION_VALUE", {id, key: "status", value: "delegated" }, {root: true})
        commit("assessment/SET_SECTION_VALUE", {id, key: "collaboration", value: collabId }, {root: true})
        resolve( collabId )
      }).catch( e => {
        reject(e )
      })
    })
  },
  return({dispatch, rootState}, {id}){
    return new Promise( (resolve) => {
      var section = rootState.assessment.sections[id]
      dispatch("updateDoc", {
        doc: `collaborations/${section.collaboration}`, 
        data: {status: "returned"}
      }, {root: true }).then( () => {      

        dispatch("updateDoc", {
          doc: `assessments/${rootState.assessment.id}`,
          data: {[`users.${rootState.user.id}`]: "dataUser" }
        }, { root: true } )

        dispatch("updateDoc", {
          doc: `assessments/${rootState.assessment.id}/sections/${id}`, 
          data: {
            status: "returned",
            [`users.${rootState.user.id}`]: "dataUser"
          }
        }, {root: true }).then( () => {
          resolve();
        })
      })
    })
  },
  undelegate({rootState, dispatch, commit}, {collabId, id} ){
    dispatch( "get", collabId ).then( data => {
      var collab = data;
      dispatch("updateDoc", {
        doc: `collaborations/${collabId}`,
        data: {status: "revoked"}
      }, { root: true } )

      var update = {
        status: "revoked", 
      }
      if( collab.user ){
        update[`users.${collab.user}`] = "dataUser"
        dispatch("updateDoc", {
          doc: `assessments/${rootState.assessment.id}`,
          data: {[`users.${collab.user}`]: "dataUser" }
        }, { root: true } )
      }
      dispatch("updateDoc", {
        doc: `assessments/${rootState.assessment.id}/sections/${id}`,
        data: update
      }, { root: true } )
      commit("assessment/SET_SECTION_VALUE", {id, key: "status", value: "revoked" }, {root: true})

    })
  },
  fetchPoll({ commit, dispatch }, id ){
    dispatch("get", id ).then((data) => {
      commit("SET", {collection: "collaborations", data, id }, {root: true})
      dispatch("fetchOne", {collection: "departments", id: data.department }, {root: true})
      dispatch("fetchOne", {collection: "goalGroups", id: data.goalGroup }, {root: true})
      dispatch("fetchOne", {collection: "goals", id: data.goal }, {root: true})
    })
  },
  // fetchPolls({commit, rootState}, {goal, goalGroup, department}){
  //   var assessment = rootState.assessment.id;
  //   firebase.firestore().collection("collaborations")
  //     .where("assessment", "==", assessment)
  //     .where("goal", "==", goal )
  //     .where("goalGroup", "==", goalGroup )
  //     .where("department", "==", department )
  //     .where("status", "in", ["published", "submitted"])
  //     .where("type", "==", "poll")
  //     .get().then( snap => {
  //       snap.forEach( doc => {
  //         var data = doc.data();
  //         var id = doc.id;
  //         commit("SET", {collection: "collaborations", data, id }, {root: true})
  //       })
  //     })
  // },
  newPoll({rootState, dispatch, commit}, data ){
    return new Promise( (resolve) => {
      data.type = "poll";
      data.author = rootState.user.id;
      dispatch("addDoc", {
        collection: "collaborations",
        data: data 
      }, { root: true }
      ).then( id => {
        commit("SET", {collection: "collaborations", data, id }, {root: true})
        return resolve( id )
      })
    })
  }, 
  savePoll({dispatch, commit}, poll ){
    dispatch("updateDoc", {
      doc: `collaborations/${poll.id}`, 
      data: {
        response: poll.data, 
        status: "submitted"
      }
    }, {root: true}).then( () => {
      commit("REMOVE_POLL", poll.id )
    })
  }
};

const mutations = {
  SET( state, {id, data } ){
    Vue.set(state, id, data )
  },
  REMOVE_POLL(state, id){
    Vue.delete( state, id );
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
