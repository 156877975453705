<template>
<v-dialog v-model="dialog" :width="mwstylesettings.SmallDialogWidth">
	<template v-slot:activator="{ on }">
		<v-btn v-if="button == 'icon'" icon :color="disabled ? 'grey' : 'green'" v-on="on">
			<v-icon>mdi-check-circle</v-icon>
		</v-btn>
		<v-btn v-else dark :color="disabled ? 'grey' : 'green'" v-on="on"  class="ml-2">{{mwtr.CompleteButton}}</v-btn>
	</template>
	<v-card>
		<v-card-title>{{mwtr.AreYouSure}}</v-card-title>
		<v-card-text>
			<p v-if="disabled">{{mwtr.CompleteAllQuestionsMessage}}</p>
			<p v-else>{{mwtr.AreYouSureReturnSection}}</p>
		</v-card-text>
		<v-card-actions>
			<v-spacer></v-spacer>
			<v-btn @click="dialog = false" text v-if="disabled">{{mwtr.BackButton}}</v-btn>
			<v-btn @click="save" color="primary" :disabled="disabled">{{mwtr.SaveButton}}</v-btn>
		</v-card-actions>
	</v-card>
</v-dialog>
</template>

<style lang="less">
	
</style>

<script type="text/javascript">
	import Vuex from "vuex"
	export default {
		name: "Return", 
		props: {
			id: {},
			disabled: {type: Boolean, default: false },
			button: {type: String, default: "block"}
		},
		data: () => {
			return {
				dialog: false,
			}
		},
		// components: {

		// },
		computed: {
			...Vuex.mapState({
				assessment: state => state.assessment
			}),
			section(){
				return this.assessment.sections[this.id]
			}
		},
		methods: {			
			save(){
				const self = this;
				self.$emit("save");
				var id = self.id;
				self.$store.dispatch("collaborations/return", {id} ).then( () => {
					self.dialog = false;
					self.$router.replace("/results")
				})
			}
		}
		// created(){
			
		// }

	}
// </script>"