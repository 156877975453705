import Vue from 'vue'
import firebase from 'firebase';

const state = {
  view: 'visitor',
  logged_in: false,
  logged_out: false, 
  uid: false, 
  email: false,

};

const actions = {
  check({commit, dispatch}){
    firebase.auth().onAuthStateChanged(user => {
      if( user ) {
        commit("SUCCESSFUL_LOG_IN", user );
        dispatch("user/fetch", user.uid, {root: true });
      }
      else{
        commit("SUCCESSFUL_LOG_OUT")
      }
    })
  },
  loggedIn( { dispatch, commit }, user ) {
    return new Promise((resolve) => {
        commit("user/SET", {what: "new", value: false }, {root: true })
        dispatch("user/updateLogin", user.uid, {root: true} ).then( () => {
          resolve(true)
        })
    })
  },
  registered( { commit }, user ) {
    return new Promise((resolve) => {
        commit("user/SET", {what: "role", value: "user"}, {root: true })
        commit("user/SET", {what: "uid", value: user.uid}, {root: true })
        commit("user/SET", {what: "new", value: true}, {root: true })
        // user.sendEmailVerification();
        resolve();
    })
  },
  logout( { commit, dispatch } ) {
    return new Promise((resolve, reject) => {
      firebase.auth().signOut().then(function() {
        commit("SUCCESSFUL_LOG_OUT");
        dispatch("detachAll", false , {root: true})
        dispatch("user/reset", false, {root: true })
        dispatch("assessment/reset", false, {root: true })
        dispatch("organisation/reset", false, {root: true })
        dispatch("removeModules", false , {root: true})
        resolve( {succesful: true} );
      }).catch( e => {
        reject( e )
      })
    })
  },
  updateView({commit, dispatch}, view ){
    dispatch("loadModules", view, {root: true })
    commit("SET_VIEW", view)
  }
};

const mutations = {
  SET_VIEW(state, view ){
    Vue.set(state, "view", view)
  },
  SUCCESSFUL_LOG_OUT(state ) {
    let now = Math.round((new Date()).getTime() / 1000);
    Vue.set( state, "logged_out", now ) 
    Vue.set( state, "logged_in", false ) 
    Vue.set( state, "uid", false )  
    Vue.set( state, "email", false )  
    Vue.set( state, "view", "visitor" )  
  },
  SUCCESSFUL_LOG_IN( state, user ) {
    let now = Math.round((new Date()).getTime() / 1000);
    // Vue.set( state, "view", "user")
    Vue.set( state, "logged_in", now ) 
    Vue.set( state, "uid", user.uid );
    Vue.set( state, "email", user.email );
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations
};
