<template>
	<v-card flat class="pa-2 navigation-wrapper">	

	</v-card>
</template>

<style lang="less">
	.navigation-wrapper {
	}
</style>

<script type="text/javascript">
	import Vuex from "vuex";
	export default {
		name: "UserNavigation", 
		props: {
			
		},
		data: () => {
			return {
				
			}
		},
		computed: {
			...Vuex.mapState({
				organisation: state => state.organisation
			}),
		},
		// methods: {
		// }
		// created(){
			
		// }

	}
// </script>"
