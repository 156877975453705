<template>
	<v-chip dark x-small color="green" v-if="havePolls">
		<v-icon x-small class="mr-2">mdi-check-circle</v-icon>{{mwtr.HavePollResponses}}
	</v-chip>
</template>

<style lang="less">
	
</style>

<script type="text/javascript">
	// import - from "@/components/"
	export default {
		name: "", 
		props: {
			department: {},
			goal: {},
			goalGroup: {}
		},
		data: () => {
			return {

			}
		},
		// components: {

		// },
		computed: {
			polls() {
				var collabs = Object.values(this.$store.state.collaborations) || [];
				collabs = collabs.filter( a => a.type == "poll" );
				return collabs;
			},
			havePolls(){
				var d = this.department;
				var polls = this.polls;
				polls = polls.filter( p => p.status == "submitted");
				polls = polls.filter( p => p.department == d );
				if( this.goalGroup ){
					var gg = this.goalGroup;
					polls = polls.filter( p => p.goalGroup == gg );
				}

				if( this.goal ){
					var g = this.goal;
					polls = polls.filter( p => p.goal == g );
				}

				return polls.length > 0;
			}
		},

	}
// </script>"