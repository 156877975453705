<template>

	<span v-if="status !== 'complete'">				
		<Complete :disabled="!showButton" v-if="lead" :id="section" button="icon"></Complete>
		<Return :disabled="!showButton" v-else :id="section" button="icon"></Return>
	</span>



</template>

<style lang="less">
	.active {
	}
</style>

<script type="text/javascript">
	import Return from "@/components/survey/tools/Return"
	import Complete from "@/components/survey/tools/Complete"
	export default {
		name: "SectionCompleteButtons", 
		props: {
			department: {}, 
		},
		data: () => {
			return {
				dialog: false
			}
		},
		components: {
			Complete,
			Return
		},
		computed: {		
			section(){
				return this.$store.getters.departmentSections[this.department]
			},
			lead(){
				return this.$store.getters.isCurrentlyLeadRespondent
			},			
			delegate(){
				return this.$store.getters.isCurrentlyDelegate
			},
			departmentCompletionRate(){
				return this.$store.getters.departmentCompletionRates[this.department];
			},
			status(){
				return this.$store.getters.igMapped[this.department].status
			},
			showButton(){
				return this.departmentCompletionRate == 1;
			}

		},
		methods: {

		},

	}
// </script>"