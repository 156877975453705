<template>
	<v-row style="margin-top: 70px;">
		<div id="mwChatWrapper"></div>
	</v-row>
</template>
<style>
	#mwChatWwrapper {
		position: absolute;
		margin-top: 30px;
	}
</style>
<script>
import Vue from "vue"
import Vuex from "vuex";

export default {
	name: "Chat",
	data: () => {
		return {
			date: false,
			intergramId: "-507937725",
			intergramCustomizations: {
				titleClosed: 'Ich brauche Hilfe',
				titleOpen: 'Hallo',
				introMessage: 'Guten Tag! Falls Sie Unterstützung benötigen, stehe ich Ihnen jederzeit zur Verfügung.',
				autoResponse: 'Vielen Dank für Ihre Nachricht. Einen Augenblick bitte.',
				autoNoResponse: 'Wie kann ich Ihnen weiter helfen?',
				mainColor: "#8291FB", 
				alwaysUseFloatingButton: true
			}    
		}
	},
	computed: {
		...Vuex.mapState({
			user: state => state.user,
		}),
		showChat(){
			var time = Vue.moment(this.date).format("HH:mm");
			if( time > "08:00" && time < "18:00" ){
				return true;
			}
			else{
				return false;
			}
		}
	},
	watch: {
		user: {
			immediate: true, 
			handler(){
				if( !window.intergramOnOpen ){
					window.intergramOnOpen = {
						visitorName: "visitor"
					}
				}
				if( this.user ){
					window.intergramOnOpen.visitorName = this.user.email; 
				}
			}
		}, 
		showChat: {
			immediate: true, 
			handler(){
				if( this.showChat ){
					this.startChat();
				}
			}
		}
	},
	methods:{
		startChat() {
			var chatScript = document.createElement('script');
			chatScript.setAttribute('src', 'https://www.intergram.xyz/js/widget.js')
			chatScript.setAttribute('id', "intergram");
			window.intergramId = this.intergramId;
			window.intergramCustomizations = this.intergramCustomizations;
			document.body.appendChild(chatScript);
			setTimeout(function() {dispatchEvent(new Event('load'))}, 200);
		}
	},
	created(){
		this.date = new Date();
	}
}
</script>