function getFields(key) {
	return Object.values(this.fields[key]).filter( field => field.display )
}
function getEmpty(key){
	var obj = {}
	Object.values(this.fields[key]).forEach( field => obj[field.value] = field.default )
	return obj;
}
function getUniqueId(){
	var result	= '';
	var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
	var charactersLength = characters.length;
	for ( var i = 0; i < 16; i++ ) {
		result += characters.charAt(Math.floor(Math.random() * charactersLength));
	}
	return result;
}


export default  {
	getFields: getFields, 
	getEmpty: getEmpty, 
	getUniqueId: getUniqueId,
	fields: {
		Goal: {
			tags: { 
				value: "tags", 
				default: [], 
				display: true, 
				label: "TagsNavItem"
			}, 
			name: {
				value: "name", 
				default: "", 
				display: true, 
				label: "GoalNameLabel"
			},
			igid: {
				label: "IGID", 
				default: "",
				value: "igid",
				display: true, 
			},
			mouseover: {
				value: "mouseover", 
				default: "", 
				display: true, 
				label: "GoalMouseoverLabel"
			}
		},
		GoalGroup: {
			tags: { 
				value: "tags", 
				default: [], 
				display: true, 
				label: "TagsNavItem"
			}, 
			description: {
				label: "DescriptionLabel", 
				default: "",
				value: "description",
				display: true, 
			},
			igid: {
				label: "IGID", 
				default: "",
				value: "igid",
				display: true, 
			},
			name: {
				value: "name", 
				default: "", 
				display: true, 
				label: "GoalGroupNameLabel"
			}
		},
		Question: {
			type: {
				label: "QuestionTypeLabel", 
				default: "mwRadiotiles",
				value: "type",
				display: false, 
			},
			title: {
				label: "QuestionTitleLabel", 
				default: "",
				value: "title",
				display: true, 
			},
			description: {
				label: "DescriptionLabel", 
				default: "",
				value: "description",
				display: true, 
			},
			visibleIf: {
				label: "QuestionVisibleIfLabel", 
				default: "",
				value: "visibleIf",
				display: false, 
			},
			scored: {
				value: "scored", 
				display: false, 
				default: false, 
			},
			score: {
				value: "score",
				label: "ScoredLabel",
				default: 0,
				display: false
			},
			mouseover: {
				value: "mouseover",
				label: "MouseoverTextLabel",
				default: "",
				display: false
			},
		},
		AnswerOption: {
			text: {
				label: "AnswerOptionValueLabel",
				value: "text", 
				default: "", 
				display: true
			},
			position: {
				value: "position", 
				default: 0, 
				display: false
			},
			value: {
				value: "value",
				label: "AnswerOptionTextLabel",
				default: "",
				display: true
			},
			scored: {
				value: "scored", 
				display: false, 
				default: true, 
			},
			score: {
				value: "scored",
				label: "ScoreLabel",
				default: 0,
				display: true
			}
		},
		Tag: {
			name: {
				label: "TagNameLabel", 
				component: "v-text-field",
				value: "name", 
				display: true, 
				default: ""
			}, 
			description: {
				label: "DescriptionLabel", 
				component: "v-textarea",
				value: "description", 
				display: true, 
				default: ""

			}, 			
		},
		Department: {
			name: {
				label: "DepartmentNameLabel", 
				component: "v-text-field",
				value: "name", 
				display: true, 
				default: ""

			}, 
			type: {
				label: "DepartmentTypeLabel", 
				options: {
					items: ["Administrative" , "Surgical", "General Medical", "Paediatric Medical", "Mental Health", "Community", "Allied Health" ]
				},
				value: "type", 
				display: true,
				component: "v-select",
				default: ""
			}
		},
	},


	AddressFields: [
		{value: "streetAddress", label: "OrganisationAddress", component: "v-text-field"},
		{value: "state", label: "OrganisationState",   component: "v-select", options: { items: ["Berlin", "Bavaria", "Brandenburg"]} },
		{value: "city", label: "OrganisationCity",  component: "v-text-field"},
		{value: "postcode", label: "OrganisationPostcode", component: "v-text-field"} ,
	],
	OrganisationTypes: ["Privat"],

}