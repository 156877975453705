import Vue from 'vue'
import VueRouter from 'vue-router'
import mwutilities from "@/assets/utilities"
Vue.use(VueRouter)
import restrictions from "@/router/restrictions.js"

const routes = [
  {
    path: '/',
    name: 'Dashboard',
    meta: {
      hasSidebar: true
    },
    component: () => import('@/views/Dashboard.vue')
  },  
  {
    path: '/poll/:id',
    name: 'Poll',
    props: true,
    meta: {
      hasSidebar: false
    },
    component: () => import('@/components/survey/poll/Render.vue')
  },  
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/components/auth/Login.vue')
  },  
  {
    path: '/ref/:code',
    name: 'Referral',
    props: true,
    meta: {
      hasSidebar: true
    },
    component: () => import('@/views/Referral.vue')
  },  
  {
    path: '/organisation-profile',
    name: 'OrganisationProfile',
    meta: {
      require_auth: true, 
      roles: ["user"],
      hasSidebar: false
    },
    component: () => import('@/views/OrganisationProfile.vue')
  }, 
  {
    path: '/admin/section-wizard',
    name: 'SectionWizard',
    meta: {
      require_auth: true, 
      hasSidebar: true,
      roles: ["admin"]
    },
    component: () => import('@/components/admin/forms/SectionWizard.vue')
  }, 
  {
    path: '/assessment-config/overview',
    name: 'AssessmentConfig',
    meta: {
      in: "assessment-config",
      require_auth: true, 
      hasSidebar: true,
      roles: ["user"]
    },
    component: () => import(/* webpackChunkName: "login" */ '@/components/user/assessment-config/AssessmentConfig.vue')
  },
  {
    path: '/assessment-config/departments',
    name: 'DepartmentSelection',
    meta: {
      in: "assessment-config",
      require_auth: true, 
      hasSidebar: true,
      roles: ["user"]
    },
    component: () => import(/* webpackChunkName: "login" */ '@/components/user/assessment-config/DepartmentSelection.vue')
  },  
  {
    path: '/assessment-config/sections',
    name: 'SectionConfig',
    meta: {
      in: "assessment-config",
      require_auth: true, 
      hasSidebar: true,
      roles: ["user"]
    },
    component: () => import(/* webpackChunkName: "login" */ '@/components/user/assessment-config/SectionConfig.vue')
  },  
  {
    path: '/manage-collaborations',
    name: 'CollaborationDashboard',
    meta: {
      require_auth: true, 
      hasSidebar: false,
      roles: ["user"]
    },
    component: () => import('@/views/CollaborationDashboard.vue')
  },  
  {
    path: '/assessment',
    name: 'Assessment',
    props: true,
    meta: {
      in: "assessment",
      require_auth: true, 
      roles: ["user"],
      hasSidebar: true
    },
    component: () => import('@/views/Assessment.vue')
  }, 
  {
    path: '/results',
    name: 'Results',
    props: true,
    meta: {
      require_auth: true, 
      roles: ["user"],
      hasSidebar: false
    },
    component: () => import('@/views/Results.vue')
  }, 
  {
    path: '/analysis',
    name: 'Analysis',
    props: true,
    meta: {
      require_auth: true, 
      roles: ["user"],
      hasSidebar: false
    },
    component: () => import('@/views/Analysis.vue')
  }, 
  {
    path: '/help',
    name: 'Help',
    component: () => import('@/views/Help.vue')

  }
]

const adminCategories = [
  "organisation", 
  "user", 
  "department", 
  "tag", 
  "goal",
  "question", 
  "goal-group",
  "section"
];


adminCategories.forEach( path => {
  var Category = path.split("-").map( a => mwutilities.upperCaseFirst(a) ).join("");
  routes.push ({
    path: `/admin/${path}s`,
    name: `List${Category}s`, 
    meta: {
      require_auth: true,
      hasSidebar: true,
      roles: ["admin"]
    },
    component: () => import(`@/components/admin/lists/${Category}s.vue`)
  })
  routes.push({
    path: `/admin/${path}s/new`,
    name: `New${Category}`,
    meta: {
      require_auth: true, 
      hasSidebar: true,
      roles: ["admin"]
    },
    component: () => import(`@/components/admin/edits/Edit${Category}.vue`)
  })
  routes.push({
    path: `/admin/${path}s/:id`,
    name: `Edit${Category}`,
    props: true,
    meta: {
      require_auth: true, 
      hasSidebar: true,
      roles: ["admin"]
    },
    component: () => import(`@/components/admin/edits/Edit${Category}.vue`)
  })
})


const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, _, next) => {
  if( !to.meta || !to.meta.require_auth ){
    next();
  }
  else{ 
    restrictions.checkAuth().then( role => {
      if( to.meta.roles ){
        if( role == "superadmin" ){
          next();
        }
        else if( to.meta.roles.indexOf(role) > -1 ){
          next();
        }
        else{
          next("/")
        }
      }
      next();
    }).catch( () => {
      next("/")
    })
  }
})

export default router
