<template>
	<v-list-item class="pl-8" dense>
		<v-list-item-content>
			<v-tooltip bottom>
				<template v-slot:activator="{on}">			
					<v-btn v-on="on" @click="dialog = 1" block color="primary">
						{{mwtr.SkipConfig}}
					</v-btn>
				</template>
				<span>{{mwtr.AcceptAllSettings}}</span>
			</v-tooltip>
		</v-list-item-content>
		<v-dialog v-model="dialog" :width="mwstylesettings.MediumDialogWidth">
			<v-card>
				<v-card-title>{{mwtr.SkipConfig}}</v-card-title>
				<v-card-text>
					<p v-if="countDepartments">Sind Sie sicher, dass Sie alle Voreinstellungen übernehmen möchten?</p>
					<p v-else>{{mwtr.AcceptEmpty}}</p>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn v-if="countDepartments == 0" color="primary" to="/assessment-config/departments" @click="dialog = false">{{mwtr.DepartmentConfig}}</v-btn>
					<v-btn v-else color="primary" @click="acceptDefault">{{mwtr.AcceptAllSettings}}</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</v-list-item>
</template>

<style lang="less">

</style>

<script type="text/javascript">
	import Vuex from "vuex";
	// import - from "@/components/"
	export default {
		name: "AcceptDefaultConfig", 
		props: {
			
		},
		data: () => {
			return {
				dialog: false
			}
		},
		// components: {

		// },
		computed: {
			...Vuex.mapState({
				config: state => state.assessment.config
			}),
			map(){
				return this.$store.getters.igMapped;
			}, 
			countDepartments(){
				if( !this.config ){
					return 0;
				}
				return Object.keys(this.config.sections).length || 0;
			}
		},
		methods: {
			acceptDefault(){
				this.$router.replace("/assessment");
			}
		}
		// created(){
			
		// }

	}
// </script>"