export default {
	PollReturnMessage:
		"Sind Sie sicher? Nach dem Speichern können keine weiteren Änderungen vorgenommen werden.",
	// PollIntro: "Bitte legen Sie pro zu befragendem Mitarbeiter eine Mini-Umfrage an und leiten Sie den angezeigten Link an ihn/sie weiter. Durch Eintragen eines Titels erkennen Sie später die individuellen Antworten Ihrer Mitarbeiter wieder.",
	PollOverview: "Übersicht Mini-Umfragen",
	LaunchPolls: "Mini-Umfragen Verwalten",
	Poll: "Mini-Umfrage",
	NewPoll: "Neue Mini-Umfrage",
	PollNickname: "Titel",
	PollLink: "Link",
	Download: "Download",
	CloseButton: "Schließen",
	NoPollsFound: "Keine Mini-Umfragen gefunden",
	NoAnswer: "Keine Antwort",
	PollThankYou:
		"Vielen Dank für Ihre Teilnahme an dieser Mini-Umfrage im Rahmen der Erfassung der digitalen Reife Ihres Hauses. Ihre Angaben wurden gespeichert und Ihre Mini-Umfrage ist hiermit abgeschlossen. Sie können dieses Fenster schließen.",
	GoalPending: "Prüfung dieses Ziels noch nicht abgeschlossen",
	AssessmentHome: "Übersicht der Fachabteilungen",
	Pending: "Ausstehend",
	Delegate: "Delegieren",
	Delegated: "Delegiert",
	ReturnButton: "Zurück",
	Complete: "Abgeschlossen",
	CompleteButton: "Abschließen",
	Paste: "Einfügen",
	SearchByTagsLabel: "Suche nach Tags",
	DepartmentConfig: "Abteilungen einrichten",
	DepartmentNameLabel: "Name der Abteilung",
	DepartmentTypeLabel: "Art der Abteilung",
	ConfirmDeletionMessage: "Bitte löschen bestätigen",
	DeleteButton: "Löschen",
	ConfirmButton: "Bestätigen",
	CopyButton: "Kopieren",
	SaveButton: "Speichern",
	ImportButton: "Importieren",
	CancelButton: "Abbrechen",
	NextButton: "Weiter",
	SubmitButton: "Absenden",
	Continue: "Weiter",
	ChangeButton: "Ändern",
	UserOrganisations: "Organisationen des Benutzers",
	Saved: "Gespeichert",
	Edit: "Bearbeiten",
	Login: "Einloggen",
	Logout: "Ausloggen",
	LoggedInAs: "Mit xxx einloggen",
	NewUserInfo:
		"Danke für die Registrierung. Bitte überprüfen Sie Ihre E-Mails für weitere Informationen.",
	OrganisationsNavItem: "Organisationen",
	DepartmentsNavItem: "Abteilungen",
	UsersNavItem: "Benutzer",
	TagsNavItem: "Tags",
	QuestionsNavItem: "Fragen",
	OrganisationNavItem: "Organisation",
	DepartmentNavItem: "Abteilung",
	UserNavItem: "Benutzer",
	Copied: "Kopiert",
	TagNavItem: "Tag",
	QuestionNavItem: "Frage",
	SectionsNavItem: "Abschnitt",
	NewSectionNavItem: "Neuer Abschnitt",
	NewOrganisationNavItem: "Neue Organisation",
	NewUserNavItem: "Neuer Benutzer",
	NewDepartmentNavItem: "Neue Abteilung",
	NewTagNavItem: "Neuer Tag",
	NewQuestionNavItem: "Neue Frage",
	OrganisationName: "Name",
	OrganisationAddress: "Addresse",
	OrganisationState: "Bundesland",
	OrganisationCity: "Stadt",
	OrganisationPostcode: "Postleitzahl",
	OrganisationOwner: "Krankenhausbetreiber",
	OrganisationType: "Art der Organisation",
	OrganisationBedNumber: "Bettenanzahl",
	UniversityHospital: "Universitätsklinik",
	EmailAddress: "Email-Addresse",
	Name: "Name",
	LeadRespondent: "Hauptverantwortlicher",
	DataUser: "Daten-Nutzer",
	EditUserAssignment: "Benutzerzuordnung bearbeiten",
	TagNameLabel: "Tag",
	AddTagButton: "Add Tag",
	TagAlreadyExistsMessage: "Dieses Tag ist bereits in der Datenbank vorhanden",
	Search: "Suche",
	AddFilter: "Filter hinzufügen",
	ClearFilters: "Filter zurücksetzen",
	QuestionNameWarningMessage:
		"Der Fragenname kann später nicht mehr bearbeitet werden. Er wird nur als ID verwendet und dem Teilnehmer nicht angezeigt.",
	QuestionNameLabel: "Fragenname",
	QuestionTypeLabel: "Question Type",
	QuestionTitleLabel: "Fragetitel",
	QuestionHelpTextLabel: "Hilfetext",
	DescriptionLabel: "Beschreibung",
	QuestionAnswerOptionsLabel: "Antwortoptionen",
	AnswerOptionTextLabel: "Text",
	AnswerOptionTextHelp: "Der Text wird dem Teilnehmer angezeigt",
	AnswerOptionValueLabel: "Wert",
	AnswerOptionValueHelp:
		"Der Wert wird als Referenz verwendet und mit den Daten exportiert.",
	ScoreLabel: "Score",
	AddAnswerOptionButton: "Antwortoption hinzufügen",
	MouseoverTextLabel: "Mouseover-Text",
	QuestionVisibleIfLabel: "Sichtbar wenn",
	QuestionSetUpLabel: "Fragenkonfiguration",
	PreviewLabel: "Vorschau",
	ErrorMessageEmptyValue: "Alle Antwortoptionen müssen einen Wert haben",
	ErrorMessageDuplicateValue: "Werte müssen einzigartig sein",
	ErrorMessageGeneral: "Bitte korrigieren Sie die Fehler auf dieser Seite",
	GoalNavItem: "Ziele",
	GoalNameLabel: "Zielname",
	GoalMouseoverLabel: "Mouseover-Hilfetext",
	NewGoalNavItem: "Neues Ziel",
	AreYouSure: "Sind Sie sicher?",
	NewTagMessage:
		"Sind Sie sicher, dass Sie der Datenbank ein neues Tag hinzufügen möchten?",
	SearchOrAdd: "Suchen Sie nach Tags oder fügen Sie ein neues hinzu",
	NewGoalGroupNavItem: "Neue Zielgruppe",
	GoalGroupsNavItem: "Zielgruppen",
	GoalGroupNameLabel: "Zielgruppenname",
	OperatorStringAnd: "Artikel muss alle Kriterien erfüllen",
	OperatorStringOr: "Artikel können beliebige Kriterien erfüllen",
	SectionConfig: "Zielkonfiguration",
	Section: "Abschnitt",
	AddSectionButton: "Abschnitt hinzufügen",
	NewSectionBlub: "",
	SectionName: "Abschnittsname",
	GoalsInGroup: "Ziele in dieser Gruppe",
	AddSectionPartButton: "Abschnittsteil hinzufügen",
	GoalGroups: "Zielgruppen",
	GoalGroupSetUp: "Zieleinstellungen",
	SaveLayout: "Neues Layout speichern",
	GoalsInGoalGroupTitle: "Ziele in dieser Zielgruppe",
	AddMoreGoals: "Mehr Ziele hinzufügen",
	AddGoals: "Ziele hinzufügen",
	AddSelectedGoals: "Ausgewählte Ziele hinzufügen",
	SaveConfig: "Einstellungen speichern",
	AssessmentConfigNavItem: "Erhebungseinstellungen",
	ComboboxHint: "Wählen Sie aus der Dropdown-Liste oder geben Sie frei ein",
	GoToAssessment: "Erhebung starten",
	HideGoalEverywhere: "Dieses Ziel überall ausblenden",
	AssessmentNavItem: "Erhebung",
	ShowTools: "Abschnitts-Tools",
	DelegateSection: "Abschnitt delegieren",
	PleaseSetUpAnAccountMessage:
		"Bitte richten Sie über das Formular ein Konto ein",
	CollaborationActiated: "Zusammenarbeitsvorgang aktiviert",
	CollaborationRevoked: "Zusammenarbeitsvorgang wurde widerrufen",
	AccessDenied: "Zugang verweigert",
	DelegationWarning: "Sie können einen delegierten Abschnitt nicht bearbeiten",
	CompleteWarning:
		"Sie können einen abgeschlossenen Abschnitt nicht bearbeiten",
	BackButton: "Zurück",
	CustomGoal: "Neues Ziel",
	AreYouSureReturnSection:
		"Sind Sie sicher, dass Sie die Bewertung an den Geschäftsbereich IT abschicken möchten?",
	ResultsNavItem: "Ergebnisse",
	AnalysisNavItem: "Analyse",
	HavePollResponses: "Antworten - Mini-Umfrage",
	Status: "Status",
	LastLogin: "Letzter Login",
	CollaborationStatus: "Status Zusammenarbeit",
	ManageCollaboration: "Zusammenarbeit verwalten",
	CollaborationStatuses: {
		activated: "Aktiv",
		published: "Inaktiv",
		revoked: "Widerrufen",
		returned: "Retourniert",
		delegated: "Delegiert",
	},
	SkipConfig: "Voreinstellungen übernehmen",
	GoalConfig: "Ziele einrichten",
	GoalGroup: "Zieldimension",
	AcceptAllSettings: "Alle Voreinstellungen übernehmen",
	ConfigOverview: "Übersicht",
	CanNotReconfigureSection:
		"Bereiche, für die bereits eine Erhebung begonnen wurde können nicht weiter konfiguriert werden",
	SelectAll: "Alle auswählen",
	DeselectAll: "Auswahl zurücksetzen",
	UmsetzungProgress: "Fortschritt Ihrer Digitalisierung",
	NewGoalWarning:
		"Neu erstellte Ziele müssen von InnGo freigegeben werden, bevor sie der Konfiguration hinzugefügt werden können",
	AcceptEmpty:
		"Um Voreinstellungen für Ziele zu akzeptieren, muss mindestens eine Abteilung aktiv sein",
	CompleteAllQuestionsMessage:
		"Bitte bewerten Sie die ausstehenden Fragen, um die Ergebnisse einreichen zu können.",
	Charts: {
		BarChart: "Säulendiagramm",
		LineChart: "Liniendiagramm",
		AreaChart: "Flächendiagramm",
		HBarChart: "Balkendiagramm",
		Average: "Durchschnitt",
		Series: "Serien",
		Departments: "Abteilungen",
		AllQuestions: "Alle Frage",
		GoalGroups: "Zieldimensionen",
		YourScore: "Meine Organisation",
		AllScores: "Alle Organisationen",
	},
};
