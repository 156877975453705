<template>
	<v-app-bar app dense v-if="auth.view" color="white" flat>
		<v-btn icon v-if="isMobile" @click="$emit('toggleSecondaryNav')"><v-icon>mdi-menu</v-icon></v-btn>
		<v-hover v-slot="{ hover }">	
			<router-link to="/" class="ig-header unstyled-link">
				<h2 class="mb-0" :class="{ 'primary2--text' : hover, 'igblack--text' : !hover}">INNGO</h2>
				<span class="black--text">Innovation Go</span>
			</router-link>
		</v-hover>

		<v-spacer></v-spacer>
		<div v-if="auth.logged_in">
			<span  v-for="(nav, name) in links" :key="name">	
				<v-btn v-if="nav.length == 1" color="ig-black" text router-link :to="nav[0].link">
					{{nav[0].name}}
				</v-btn>
				<v-menu v-else dense open-on-hover top>
					<template v-slot:activator="{ on }">
						<v-btn color="ig-black" text v-on="on">
							{{name}} <v-icon small>mdi-chevron-down</v-icon>
						</v-btn>
					</template>
					<v-list dense >
						<v-list-item  v-for="(item, index) in nav" :key="index" router-link :to="item.link">
							<v-list-item-title>{{ item.name }}</v-list-item-title>
						</v-list-item>
					</v-list>
				</v-menu>
			</span>		
		</div>
		<v-btn icon router-link to="/help">
			<v-icon>mdi-help-circle</v-icon>
		</v-btn>
		<span v-if="auth.logged_in">	
			<Logout></Logout>
		</span>	
	</v-app-bar>
</template>

<style lang="less">
	.unstyled-link{
		text-decoration: none;
	}
</style>

<script type="text/javascript">
	import Vuex from "vuex";
	import Logout from "@/components/auth/Logout"	
	// import LoggedIn from "@/components/auth/LoggedIn"
	export default {
		name: "MainNavigation", 
		props: {
			
		},
		data: () => {
			return {
			}
		},
		components: {
			Logout, 
			// LoggedIn
		},
		computed: {
			...Vuex.mapState({
				user: state => state.user,
				auth: state => state.auth
			}),
			isLead(){
				return this.$store.getters.isCurrentlyLeadRespondent;
			},
			canAccessAssessment(){
				return this.$store.getters.canAccessAssessment;
			},
			canAccessData(){
				return this.$store.getters.canAccessData;
			},
			leadLinks(){
				return {
					Management: [
						{
							link: "/assessment-config/overview", 
							name: this.mwtr.AssessmentConfigNavItem
						},
						// { 
						// 	link: "/dashboard",
						// 	name: "Dashboard"
						// },
						{ 
							link: "/manage-collaborations",
							name: this.mwtr.ManageCollaboration
						},
						{ 
							link: "/organisation-profile",
							name: "Profile"
						},
					]
				}
			},
			links() {
				const self = this;
				var links = {
					Home: [{
						link: "/",
						name: "Home"
					}]
				}
				if( self.canAccessAssessment ){
					links.Erhebung = [
						{
							link: "/assessment", 
							name: this.mwtr.AssessmentNavItem
						}
					]	
				}
				if( self.canAccessData ){	
					links.Ergebnisse = [
						{
							link: "/results", 
							name: this.mwtr.ResultsNavItem
						},
						{
							link: "/analysis", 
							name: this.mwtr.AnalysisNavItem
						}
					]		
				}

				if( self.isLead ){
					Object.keys(self.leadLinks).forEach( i => {
						if( !links[i] ){
							links[i] = self.leadLinks[i]
						}
						else{
							links[i] = links[i].concat(self.leadLinks[i])
						}
					})
				}
				return links;
			},
			isMobile(){
				return this.$vuetify.breakpoint.smAndDown
			}
		},

	}
// </script>"