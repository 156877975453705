import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

export default new Vuetify({
	theme: {
		themes: {
			light: {
				primary: "#8291FB", 
				primary2: "#dad9f7", 
				secondary: "#F1F1F1",
				igblack: "#050719", 
				igblue: "#2754FE", 
				igaccent: "#CFF315"
			}
		}
	}
});
