import Vue from 'vue'
import firebase from 'firebase';

var getEmpty = () => {
	return {	
		id: false, 
		config: false,
		sections: {}, 
		organisation: false, 
		status: false, 
		users: false,
		role: false	
	}
}


const state = getEmpty();

const actions = {
	reset({commit}){
		commit("RESET" )
	},
	start({commit, dispatch}, {id, config} ){
		commit( "SET", {id: id} )
		if( config ){
			commit( "SET", {config: config } )
		}
		dispatch("fetch").then(() => {
			dispatch("fetchSections");
			dispatch("collaborations/fetchAllInAssessment", id, {root: true});
			dispatch("goalGroups/fetchAll",  false , {root: true });
			dispatch("goals/fetchAll",  false , {root: true });
			dispatch("departments/fetchAll",  false , {root: true });
			dispatch("questions/fetchAll",  false , {root: true });
		})
	},
	fetch({commit, rootState} ){
		return new Promise( (resolve, reject) => {
			if( !state.id ){
				return reject()
			}
			var userId = rootState.user.id;
			var subscription = firebase.firestore().doc(`assessments/${state.id}` )
			.onSnapshot( doc => {
				if( !doc.exists ){
					return;
				}
				var data = doc.data();
				commit("SET", data)
				if( data.users[userId] ){
					commit("SET", {role: data.users[userId]} );
				}
				return resolve()
			})
			commit("SUBSCRIBE", {subscription, id: state.id }, {root: true});
		})
	},
	addAssessmentSection({commit, dispatch}, {id, data} ){
		commit( "SET_SECTIONS", {id: id, data: data } )
		dispatch( "sections/fetchOne", id, {root: true} )
		dispatch("fetchSectionData", id );
	},
	fetchOnlyMySections({dispatch, state, rootState}){
		var userId = rootState.user.id;
		var role = state.role;
		firebase.firestore().doc(`assessments/${state.id}`).collection("sections").where(`users.${userId}`, "==", role ).get( ).then( snap => {
			snap.forEach( doc => {
				dispatch("addAssessmentSection", {id: doc.id, data: doc.data()})
			})
		})
	},
	fetchAllSections({dispatch, state}){
		firebase.firestore().doc(`assessments/${state.id}`).collection("sections").get( ).then( snap => {
			snap.forEach( doc => {
				dispatch("addAssessmentSection", {id: doc.id, data: doc.data()})
			})
		})
	},
	fetchSections({dispatch, state}){
		var role = state.role;
		if( role == "leadRespondent" ){
			dispatch("fetchAllSections")
		}
		else{
			dispatch("fetchOnlyMySections")
		}
	},
	fetchSectionData({commit, state }, section){
		if( !state.id ){
			return;
		}
		firebase.firestore().collection(`assessments/${state.id}/sections/${section}/data`).get().then( snap => {
			snap.forEach( doc => {
				var data = doc.data()
				commit("SET_SECTION_DATA", { section: section, id: doc.id, data: data })
			} );
		})
	},
	newSection({dispatch, commit}, section ){
		var data = {status: "new", section: section }
		dispatch("setDoc", { 
			doc: `assessments/${state.id}/sections/${section}`, 
			data: data 
		} , {root: true} ).then( () => {
			commit("SET_SECTIONS", {id: section , data })
		})
	},	
	saveDepartmentConfig({dispatch, state, commit }, departments){
		commit("SET_CONFIG_SECTIONS", departments );
		var config = state.config || {};
		config.sections = departments;
		dispatch("saveConfig", config);
		return;
	},
	addGoalToConfig({state, commit}, {goal, department, part}){
		var config = state.config
		if( !config.sections[department][part] ){
			config.sections[department][part] = [];
		}
		config.sections[department][part].push( goal );
		commit("SET", {config: config })
	},
	removeGoalFromConfig({state, commit}, {goal, department, part}){
		var config = state.config
		config.sections[department][part] = config.sections[department][part].filter( g => g !== goal );
		commit("SET", {config: config })
	},
	updateAssessmentSections({dispatch, state}, sections){
		return new Promise( resolve => {
			var existingSections = Object.keys(state.sections);
			sections.forEach( section => {
				if( existingSections.indexOf(section) < 0 ){
					dispatch("newSection", section );
					dispatch( "sections/fetchOne", section, {root: true} );
					resolve()
				}
			})
		})
	},
	saveConfig({dispatch, commit, rootState}, config ){
		return new Promise( resolve => {
			dispatch("updateDoc",  {
				doc: `organisations/${rootState.organisation.id}`,
				data: {assessmentConfig: config }
			}, {root: true} ).then( ( ) => {
				commit( "SET", {config: config})
				resolve(true);
			})
		})
	},
	saveSectionData({state, rootState, dispatch, commit}, saving ){
		if( !state.id ){
			return;
		}
		var response = {
			user: rootState.user.id,
			data: saving.data, 
			goal: saving.goal, 
			part: saving.part, 
		}

		for( var key in response.data ){
			if( typeof response.data[key] == "undefined" ){
				delete response.data[key];
			}
		}

		if( saving.id ){		
			dispatch("updateDoc", {
				doc: `assessments/${state.id}/sections/${saving.section}/data/${saving.id}`,
				data: response
			}, {root: true }).then( () => {
				commit("SET_SECTION_DATA", { section: saving.section, id: saving.id, data: response })				
			})
		}
		else{
			dispatch("addDoc", {
				collection: `assessments/${state.id}/sections/${saving.section}/data/`,
				data: response
			}, {root: true }).then( id => {
				commit("SET_SECTION_DATA", { section: saving.section, id: id, data: response })
			})	
		}	
	},
	saveSectionStatus({commit, dispatch, state}, {id, status} ){
		dispatch("updateDoc", {
			doc: `assessments/${state.id}/sections/${id}`,
			data: {status: status }
		}, {root: true })
		commit("SET_SECTION_VALUE", {id, key: "status", value: status})
	},
};

const mutations = {
	SET(state, values ){
		for( var key in values ){
			if( typeof state[key] !== "undefined" ){
				Vue.set( state, key, values[key] )
			}
		}
	},
	RESET(state){
		Object.assign(state, getEmpty())
	},
	RESET_SECTION(state, {id} ){
		Vue.delete( state.sections, id );
	},
	SET_SECTIONS(state, {id, data}){
		Vue.set( state.sections, id, data )
	},
	SET_SECTION_VALUE(state, {id, key, value}){
		Vue.set( state.sections[id], key, value )
	},
	SET_SECTION_DATA(state, {section, id, data}){
		if( !state.sections[section].data ){
			Vue.set(state.sections[section], "data", {} )
		}
		Vue.set( state.sections[section].data, id, data )
	},
	SET_CONFIG_SECTIONS( state, sections ){
		if( !state.config ){
			Vue.set(state, "config", {})
		}
		var d  = {...sections} || {};
		Vue.set(state.config, "sections", d )
	}
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
