import firebase from "firebase";
import Vue from "vue";

const state = {};
const actions = {
  fetchAll({dispatch}){
    dispatch("fetchAll", "assessments", {root: true })
  }, 
  fetchOne({dispatch}, id){
    dispatch("fetchOne", {collection: "assessments", id: id }, {root: true})
  }, 
  fetchSectionData({commit }, {assessment, section}){
    firebase.firestore().collection(`assessments/${assessment}/sections/${section}/data`).get().then( snap => {
      snap.forEach( doc => {
        var data = doc.data()
        commit("SET_SECTION_DATA", {assessment, section: section, id: doc.id, data: data })
      } );
    })
  },
  fetchSections({dispatch, commit}, assessment){
    firebase.firestore().doc(`assessments/${assessment}`).collection("sections").get( ).then( snap => {
      snap.forEach( doc => {
        commit( "SET_SECTIONS", {assessment, section: doc.id, data: doc.data() } )
        dispatch("sections/fetchParts", doc.id, {root: true} );
        dispatch("fetchSectionData", {section: doc.id, assessment })
      })
    })
  }, 
  new({dispatch}, data ){
    return dispatch("new", {collection: "assessments", data: data }, {root: true})
  },
  updateUsers( {state, commit}, {assessment, users} ){
    var now = firebase.firestore.Timestamp.fromDate(new Date());
    var current = state[assessment].users;
    if( !current ){
      current = {}
    } 
    for( var id in users ){
      current[id] = users[id];
    }
    firebase.firestore().doc(`assessments/${assessment}`).update({users: current, updated: now})
    commit("SET_VALUE", {id: assessment, key: "users", value: current })
  }, 
  deleteUser({state, commit}, {assessment, user} ){
    console.log(assessment)
    if( state[assessment] ){
      var current = state[assessment].users;
      delete current[user];
      var now = firebase.firestore.Timestamp.fromDate(new Date());
      firebase.firestore().doc(`assessments/${assessment}`).update({users: current, updated: now})
      commit("SET_VALUE", {id: assessment, key: "users", value: current })
    }
  }
};

const mutations = {
  SET_VALUE(state, {id, key, value}){
    Vue.set(state[id], key, value)
  },
  SET_SECTIONS(state, {assessment, section, data}){
    if( !state[assessment].sections ){
      Vue.set( state[assessment], "sections", {})
    }
    Vue.set( state[assessment].sections, section, data )
  },
  SET_SECTION_DATA(state, {assessment, section, id, data}){
    if( !state[assessment].sections[section].data ){
      Vue.set(state[assessment].sections[section], "data", {} )
    }
    Vue.set( state[assessment].sections[section].data, id, data )
  }

};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
