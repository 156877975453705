import Vue from 'vue'
import firebase from 'firebase';

const state = {};

const actions = {
  fetchAll({commit}){
    firebase.firestore().collection("departments").where("status", "!=", "deleted").get().then( snap => {
      snap.forEach( doc => {
        var data = doc.data();
        data.id = doc.id;
        commit("SET", {what: doc.id, value: data })
      })
    })
  }, 
  fetchOne({commit}, id){
    console.log(id)
    var subscription = firebase.firestore().doc("departments/" + id).onSnapshot( doc => {
        commit("SET", {what: doc.id, value: doc.data() })
    })
    commit("SUBSCRIBE", {subscription, id: id}, {root: true})
  }, 

};

const mutations = {
  SET(state, {what, value} ) {
    Vue.set(state, what, value )
  },
  RESET(state){
    Object.keys(state).forEach( key => {
      Vue.set( state, key, null )
    })
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
