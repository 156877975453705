import Vue from 'vue'
import firebase from 'firebase';

const state = {};

const actions = {
  fetchAll({commit}){
    firebase.firestore()
      .collection("users")
      .where("role", "==", "user")
      .where("status", "in", ["published", "updated", "new"])
      .get().then( snap => {
      snap.forEach( doc => {
        var userData = doc.data();
        userData.id = doc.id;
        commit("SET", {what: doc.id, value: userData })
      })
    })
  }, 
  fetchOne({dispatch}, id){
    dispatch("fetchOne", {collection: "users", id: id }, {root: true})
  }, 
  new({commit, dispatch}, data ){
    return new Promise( (resolve, reject) => {
      if(!data.role ){
        data.role = "user";
      }
      dispatch( "addDoc", {
        collection: "users", 
        data: data
      }, {root: true}).then( id => {
        data.id = id;
        commit("SET", {what: id, value: data });
        resolve(id)
      }).catch( e => reject(new Error( e ) ))
    })
  }
};

const mutations = {
  SET(state, {what, value} ) {
    Vue.set(state, what, value )
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
