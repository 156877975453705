<template>
	<v-dialog v-model="dialog" :width="mwstylesettings.SmallDialogWidth">
        <v-card>
            <v-card-title>Sind Sie noch da?</v-card-title>
            <v-card-text>
                Sie sind seit 20 Minuten nicht mehr aktiv gewesen. Aus Sicherheitsgründen werden Sie in {{seconds}} Sekunden automatisch ausgeloggt.
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn @click="logout" color="ig-blue">
                    {{mwtr.Logout}}       
                </v-btn>
                <v-btn @click="dialog = false" color="primary">
                    {{mwtr.CancelButton}}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<style lang="less">
	
</style>

<script type="text/javascript">
	export default {
		name: "AutomaticLogOut",
		data: () => {
			return {
                seconds: 30,
                dialog: false
			}
		},
		computed: {
			idle() {
				return this.isAppIdle;
			}
		},
        watch: {
            idle(idle){
                if( idle ){
                    this.dialog = true;
                    this.countDown();
                }
                else{
                    this.dialog = false;
                    this.seconds = 30;
                }
            },
            seconds(){
                if( 0 == this.seconds ){
                    this.logout();
                }
            }
        },
		methods: {
			logout(){
				const self = this;
				self.$store.dispatch("auth/logout").then( () => {
					if( self.$route.path !== "/" ){
						self.$router.replace("/")
					}
				})
			},
            countDown() {
                if( this.idle ){
                    if(this.seconds > -1) {
                        setTimeout(() => {
                            this.seconds -= 1
                            this.countDown()
                        }, 1000)
                    }
                }
            }
        }
	}
</script>"