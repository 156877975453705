export default {
	detachAll({state, commit}){	
	// Loop through all subscriptions and unsubscribe
		Object.keys(state.subscriptions).forEach( s => {
			state.subscriptions[s]()
			commit("DELETE", {where: "subscriptions", what: s })
		})
	}, 
	detachOne({state, commit}, id){
		// if subscribed, unsubscribe

		if( state.subscriptions[id] ){
			state.subscriptions[id]()
		}
		// and delete the subscription from the state
		commit("DELETE", {where: "subscriptions", what: id })
	}, 

}