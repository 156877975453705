<template>
<v-chip x-small class="ml-1 pa-1 white--text" :color="color" >{{content}}</v-chip>
</template>

<style lang="less">
	
</style>

<script type="text/javascript">
	// import Vuex from "vuex";
	// import - from "@/components/"
	export default {
		name: "CompletionRateBadge", 
		props: {
			goal: {},
			department: {},
			part: {},
			// rate: {},
			percentage: { type: Boolean, default: true }
		},
		data: () => {
			return {

			}
		},
		// components: {

		// },
		computed: {
			// ...Vuex.mapState({})
			departmentCompletionRates(){
				return this.$store.getters.departmentCompletionRates;
			},
			uniqueId(){
				return `${this.department}-${this.part}-${this.goal}`;
			},
			goals(){
				return this.$store.getters.completionRatesFlat
			},
			rate(){
				if( this.goal ){
					var uniqueId = this.uniqueId;
					var value = this.goals.find( a => a.uniqueId == uniqueId );
					if( value ){
						return value.completion || 0;
					}
					else{
						return 0;
					}
				}
				else{
					var values = [];
					if( this.department && !this.part ){
						return this.departmentCompletionRates[this.department];
					}
					else if( this.part ){
						var department = this.department;
						values = this.goals.filter( a => a.department == department )
						var part = this.part;
						values = this.goals.filter( a => a.part == part )
					}
					if( this.percentage ){
						var sum  = values.reduce(( sum, value ) => {
							return sum + (value.completion || 0);
						}, 0 )
						var count = values.length;
						return sum / count;
					}
					else{
						return values.filter( a => a.completion == 1 ).length;
					}
				}
			},
			color(){
				if( this.rate == 0 ){
					return "grey"
				}
				else if( this.rate == 1 ) {
					return "green"
				}
				else{
					return "primary"
				}
			},
			content(){
				if( this.percentage ){
					return Math.floor(this.rate * 100) + "%"
				}
				else{
					return this.rate;
				}
			},
			cr(){
				var uniqueId = this.uniqueId;
				var value = this.goals.find( a => a.uniqueId == uniqueId );
				return value;
			}
		},
		// methods: {
		// }
		// created(){
			
		// }

	}
// </script>"