<template>
	<v-card flat class="pa-2 navigation-wrapper">	
		<v-list v-model="open" :multiple="true"> 
			<v-list-item @click="goHome">
				<v-list-item-icon>
					<v-icon>mdi-home</v-icon>
				</v-list-item-icon>
				<v-list-item-content>
					<v-list-item-title>{{mwtr.AssessmentHome}}</v-list-item-title>
				</v-list-item-content>
			</v-list-item>
			<template  v-if="canAccessAssessment">
				<SectionNavigation v-for="key in order" :key="key" v-bind="map[key]"></SectionNavigation>
			</template>
		</v-list>
	</v-card>
</template>


<style lang="less">
	@import "../../assets/css/variables.less";

	.v-application--is-ltr .v-list-group--sub-group .v-list-group__header, 
	.v-application--is-ltr .v-list-group--no-action.v-list-group--sub-group > .v-list-group__items > .v-list-item {
		padding-left: 22px;
	}
	.active-section{
		border-left: 3px solid @ig-green;
	}
	.v-list-item.active {
		background-color: lighten(@primary,20%);
	}
</style>


<script type="text/javascript">
	import Vuex from "vuex";
	import SectionNavigation from "@/components/navigation/SectionNavigation"
	export default {
		name: "AssessmentNavigation", 
		props: {
			
		},
		data: () => {
			return {
				open: []
			}
		},
		components: {
			SectionNavigation
		},
		computed: {
			...Vuex.mapState({
				organisation: state => state.organisation, 
				assessment: state => state.assessment, 
			}),
			order(){
				return this.$store.getters.orderOfDepartments
			},
			map(){
				return this.$store.getters.igMapped
			},
			canAccessAssessment(){
				return this.$store.getters.canAccessAssessment
			}
		},
		methods: {
			goHome(){
				this.$store.commit("GO_HOME")
			}
		}

	}
// </script>"