import Vue from 'vue'
import firebase from 'firebase';

const state = {};

const actions = {
  fetchAll({commit}){
    firebase.firestore().collection("organisations").where("status", "!=", "deleted").get().then( snap => {
      snap.forEach( doc => {
        var orgData = doc.data();
        orgData.id = doc.id;
        commit("SET", {what: doc.id, value: orgData })
      })
    })
  }, 
  fetchOne({commit}, id){
    if( !id ){
      return;
    }
    var subscription = firebase.firestore().doc("organisations/" + id).onSnapshot( doc => {
        commit("UPDATE_ORG", {org: doc.id, data: doc.data() })
    })
    commit("SUBSCRIBE", {subscription, id: id}, {root: true})
  }, 
  new({commit, dispatch}, data ){
    return new Promise( (resolve, reject) => {
      dispatch( "addDoc", {
        collection: "organisations", 
        data: data
      }, {root: true}).then( id => {
        data.id = id;
        dispatch("newAssessment", id )
        commit("SET", {what: id, value: data });
        resolve(id)
      }).catch( e => reject(new Error( e ) ))
    })
  }, 
  newAssessment({dispatch}, organisation ){
    dispatch("addDoc", {
      collection: "assessments", 
      data: {
        organisation: organisation
      }
    }, {root: true}).then( (id) => {
      dispatch("updateDoc", {
        doc: `organisations/${organisation}`, 
        data: {assessment: id }
      }, {root: true} )

    })
  },
  fetchUsers({commit}, org ){
    if( !org ){
      return;
    }
    firebase.firestore().collection("organisations/" + org + "/users").get().then( snap => {
      snap.forEach( doc => {
        var data = doc.data()
        commit("SET_USER", {org: org, id: doc.id,  data })
      } );
    })
  },
  setUser({commit, dispatch}, {org, id, data } ){
    dispatch("updateDoc", {
      data: data, 
      doc: "organisations/" + org + "/users/" + id 
    }, {root: true} )
    commit( "SET_USER", {org, id, data })
  },
  addUser({commit, dispatch}, {org, data } ){
    dispatch("setDoc", {
      data: data, 
      doc: `organisations/${org}/users/${data.user}` 
    }, {root: true} ).then( ref => {
      commit( "SET_USER", {org, id: ref, data })
    })
  }
};

const mutations = {
  SET_USER( state,  {org, id, data} ){
    if( !state[org].users ){
      Vue.set(state[org], "users", {} )
    }
    Vue.set(state[org].users, id, data )
  },
  SET(state, {what, value} ) {
    Vue.set(state, what, value )
  },
  UPDATE_ORG(state, {org, data }){
    data.id = org;
    if( !state[org] ){
      Vue.set(state, org, data );
    }
    for( var key in data ){
      Vue.set(state[org], key, data[key]);
    }
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
