import firebase from "firebase";
import subscriptions from "./subscriptions.js";
import moduleLoaders from "./moduleLoaders.js";

export default {
	detachAll: subscriptions.detachAll,
	detachOne: subscriptions.detachOne,
	loadModules: moduleLoaders.load, 
	removeModules: moduleLoaders.remove,
	deleteDoc( {rootState, commit}, {id, collection} ){
		return new Promise( (resolve, reject) => {
			var doc = collection + "/" + id;
			//set to deleted in db so they are no longer picked up by fetchAll
			firebase.firestore().doc(doc).update({status: "deleted"}).then(() => {
				// delete from our own store
				if( rootState[collection]) {
					commit("DELETE", {where: collection, what: id })
				}
				return resolve()
			}).catch( e => reject( new Error(e) ))
		})			
	},
	updateDoc( _ , {doc, data} ){
		if( !data ){ return; }
		data.updated = firebase.firestore.Timestamp.fromDate(new Date());
		firebase.firestore().doc(doc).update(data)
	},
	setDoc( _ , {doc, data} ){
		if( !data ){ return; }
		data.created = firebase.firestore.Timestamp.fromDate(new Date());
		firebase.firestore().doc(doc).set(data)
	},
	addDoc( _ , {collection, data} ){
		return new Promise( (resolve, reject) => {
			if( !data ){ return; }
			for( var key in data ){
				if( typeof data[key] == "undefined" ){
					data[key] = null;
				}
			}
			if( !data.status ){
				data.status = "published";
			}
			data.created = firebase.firestore.Timestamp.fromDate(new Date());
			firebase.firestore().collection(collection).add(data).then( ref => {
				return resolve(ref.id);
			} ).catch( e => {
				return reject( new Error(e) )
			})
		})
	}, 
	fetchAll({commit}, collection){
		firebase.firestore().collection(collection).where("status", "!=", "deleted").get().then( snap => {
			snap.forEach( doc => {
				var data = doc.data();
				data.id = doc.id;
				commit( "SET", {collection: collection, id: doc.id, data })
			})
		})
	},
	fetchOne({commit}, {collection, id}){
		var subscription = firebase.firestore().doc(collection + "/" + id).onSnapshot( doc => {
			commit("SET", {collection: collection, id: doc.id, data: doc.data() })
		})
		commit("SUBSCRIBE", {subscription, id: id}, {root: true})
	},
	new({commit, dispatch}, {collection, data} ){
		return new Promise( (resolve, reject) => {
			dispatch( "addDoc", {
				collection: collection, 
				data: data
			}, {root: true})
			.then( id => {
				data.id = id;
				commit("SET", {collection, id, data })
				resolve(id)
			}).catch( e => reject(new Error( e ) ))
		})
	}
}