import Vue from 'vue'
import firebase from 'firebase';

let getEmpty = () => {
  return {  
    uid: false,
    id: false,
    email: false, 
    firstName: false, 
    lastName: false,
    organisations: [],
    role: false
  }
};

const state = getEmpty();

const actions = {
  reset({commit}){
    commit("RESET" )
  },
  getRole( {state}, uid ){
    return new Promise( (resolve, reject) => {
      if(!uid){
        return reject();
      }
      if( state.role ){
        return resolve(state.role)
      }
      else{
        firebase.firestore().doc("users/" + uid ).get().then( doc => {
          return resolve(doc.data().role)
        }).catch( () => {
          return reject();
        })
      }
    })
  },
  fetch({commit, dispatch}, uid){
    var subscription = firebase.firestore().doc("users/" + uid).onSnapshot( (doc) => {
      if( !doc.exists ){
        return;
      }
      var data = doc.data();
      commit("SET", { what: "id", value: doc.id } )
      for( var what in data ){
        var value = data[what];
        commit("SET", { what, value } )
      }   
      dispatch("auth/updateView", data.role, {root: true} )
    })
    commit("SUBSCRIBE", {subscription, id: uid}, {root: true})
  }, 
  updateLogin({dispatch}, uid){
    return new Promise( (resolve) => {
      var now = firebase.firestore.Timestamp.fromDate(new Date());
      dispatch("updateDoc", {data: {lastLogin: now }, doc: "users/" + uid }, {root: true });
      resolve();
    })
  }, 
};

const mutations = {
  SET(state, {what, value} ) {
    Vue.set(state, what, value )
  },
  RESET(state){
    Object.assign(state, getEmpty())
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
