import mwutilities from "@/assets/utilities";
import mwsurveyutilities from "@/assets/surveyutilities";

export default  {
	canAccessAssessment(state){
		if( !state.assessment) {
			return false;
		}
		return ["leadRespondent", "delegate"].includes(state.assessment.role)
	},
	canAccessData(state){
		if( !state.assessment) {
			return false;
		}
		return ["leadRespondent", "delegate", "dataUser"].includes(state.assessment.role)
	},
	activeGoals(state){
		// return Object.keys(state.goals).filter( g => state.goals[g].status !== "pending" ).map( g => {
		// 	return {value: g, text: state.goals[g].name }
		// })
		return Object.keys(state.goals).map( g => {
			return {value: g, text: state.goals[g].name }
		})
	},
	currentOrganisation(state){
		if( !state.user ){
			return false;
		}
		return state.user.organisations ? state.user.organisations[0] : false
	},
	currentAssessment(state){
		if( !state.organisation ){
			return false;
		}
		return state.organisation.assessment || false;
	},
	currentView(state){
		var department = state.currentDepartment; 
		var part = state.currentPart;
		var goal = state.currentGoal;
		if( !goal || !part || !department ){
			return false
		}
		return {department, part, goal}
	},
	defaultGoalsFlat(state, getters){
		var items = [];   
		var map = getters.igMapped;
		Object.keys(map).forEach( (department) => {
			var section = state.sections[map[department].section];
			Object.keys(section.parts).forEach( partId => {
				section.parts[partId].goals.forEach( goal => {          
					items.push({
						department: department, 
						part: partId, 
						goal: goal, 
						uniqueId: `${department}-${partId}-${goal}`
					})
				})
			})
		})
		return items;
	},
	defaultPartOrder(state, getters){
		var section = state.sections[getters.currentSection.section];
		var goalGroups = state.goalGroups;
		var parts = {};
		Object.keys(section.parts).forEach( p => {
			var g = section.parts[p].goalGroup;
			parts[p] = { igid:  goalGroups[g].igid };
		} )
		return mwutilities.getPositions( parts, "igid" );
	},
	partNames(state){
		var obj = {};
		var sections = state.sections;
		Object.values( sections ).forEach( s => {
			var parts = Object.keys( s.parts );
			parts.forEach( p => {
				var g = s.parts[p].goalGroup;
				obj[p] = state.goalGroups[g].name;
			})
		})
		return obj
	}, 
	partGoalGroups(state){
		var obj = {};
		var sections = state.sections;
		Object.values( sections ).forEach( s => {
			var parts = Object.keys( s.parts );
			parts.forEach( p => {
				var g = s.parts[p].goalGroup;
				obj[p] = g;
			})
		})
		return obj
	}, 
	currentSection(state, getters){
		var id = state.currentDepartment
		if( !id ){
			return false;
		}
		var map = getters.igMapped;
		return map[id] || false;
	},
	currentPart(state, getters){
		var id = state.currentPart
		if( !id ){
			return false;
		}
		var currentSection = getters.currentSection;
		return currentSection.parts[id];
	},
	currentGoalUniqueId(state){
		return `${state.currentDepartment}-${state.currentPart}-${state.currentGoal}`; 
	},
	getNext(state, getters){
		var current = getters.currentGoalUniqueId;
		var goals = getters.igMappedFlat;
		var index = goals.indexOf( goals.find( a => a.uniqueId == current ) );
		var next = index + 1;
		if( next == goals.length ){
			return false;
		}
		return goals[next];
	},
	currentGoal(state, getters){
		var id = state.currentGoal;
		if( !state.currentGoal ){
			return;
		}
		var goal = state.goals[id];
		if( !goal ){
			return
		}
		var data = {
			id: false, 
			data: {}
		};
		var sectionId = getters.currentSection.section;
		if( state.assessment.sections[sectionId] ){
			var part = state.currentPart;
			var sectionData = state.assessment.sections[sectionId].data;
			if( sectionData ){
				var dataId = Object.keys(sectionData).find( d => {
					return 	sectionData[d].goal == id && 
					sectionData[d].part == part
				} );
				if( dataId ){
					data = {
						id: dataId, 
						data: sectionData[dataId].data
					}
				}
			}
		}
		return {
			id: state.currentGoal,
			name: goal.name, 
			status: goal.status,
			tags: goal.tags,
			response: data
		}
	},
	currentPolls(state, getters){
		var assessment = getters.currentAssessment
		var goalGroup = getters.currentPart.goalGroup
		var goal = state.currentGoal;
		var department = state.currentDepartment;
		var obj = {}
		Object.keys(state.collaborations)
		.filter( c => {
			var collab = state.collaborations[c];
			return collab.type == "poll" && 
			collab.assessment == assessment && 
			collab.goal == goal &&
			collab.goalGroup == goalGroup &&
			collab.department == department

		}).forEach(p => {
			obj[p] = state.collaborations[p]
		} )
		return obj;
	},
	currentPollsByQuestion(state, getters){
		state;
		var polls = getters.currentPolls;
		var ids = Object.keys(polls).filter( id => {
			return polls[id].status == "submitted"
		});
		var obj = {}; 
		ids.forEach( id => {
			var poll = polls[id];
			for( var key in poll.response ){
				if( !obj[key] ){
					obj[key] = {};
				}
				obj[key][id] = poll.response[key]
			}
		} )
		return obj;
	},
	isCurrentlyLeadRespondent(state){
		if( !state.assessment ){
			return false;
		}
		return state.assessment.users[state.user.id] == "leadRespondent"
	},
	isCurrentlyDelegate(state){
		if( !state.assessment ){
			return false;
		}
		return state.assessment.role == "delegate"
	},
	orderOfDepartments(state, getters){
		var map = getters.igMapped;
		var keys = Object.keys(map);
		return keys.sort( (a, b ) => {
			var textA = map[a].name;
			var textB = map[b].name;
			return (textA < textB) ? -1 : (textA > textB) ? 1 : 0
		})
	},
	igMapped(state, getters){
		var map = {}
		if( !state.assessment || !state.assessment.config ){
			return map;
		}
		var config = state.assessment.config.sections;
		if( config ){
			Object.keys(config).map( departmentId => {
				var department = state.departments[departmentId];
				if( !department ){
					return;
				}
				var section = state.sections[department.section];
				if( !section ){
					return;
				}
				var assessmentSection = state.assessment.sections[department.section];
				if(!assessmentSection){
					return;
				}
				var goalGroups = state.goalGroups;
				var status = assessmentSection.status;
				if (status == "delegated"){
					if( !getters.isCurrentlyLeadRespondent ){
						if( assessmentSection.users[state.user.id] == "delegate" ){
							status = "in_progress";
						}
					}
				}
				map[departmentId] = {
					name: department.name, 
					department: departmentId,
					status: status,
					section: department.section,
					parts: {}
				}
				var parts = {}
				Object.keys(config[departmentId]).forEach( (partId) => {
					if( section.parts ){
						var part = section.parts[partId];
						if( part ){

							var sortedGoals = config[departmentId][partId].sort( (a, b) => {
									var posA = section.parts[partId].goals.indexOf(a);
									var posB = section.parts[partId].goals.indexOf(b);
								return (posA < posB) ? 1 : (posA > posB) ? -1 : 0;
							})
							
							if( sortedGoals.length ){							
								parts[partId] = {
									part: partId, 
									goalGroup: part.goalGroup,
									name: state.goalGroups[part.goalGroup].name,
									position: part.position, 
									igid: goalGroups[part.goalGroup].igid, 
									goals: sortedGoals
								}
							}
						}
					}
				})
				map[departmentId].partOrder = mwutilities.getPositions( parts, "igid" );
				map[departmentId].parts = parts; 
			} )
		}
		Object.keys( map ).forEach( d => {
			if( Object.keys(map[d].parts).length == 0 ){
				delete map[d]
			}
		})
		return map;
	},
	igMappedFlat(state, getters){
		var items = []
		var map = getters.igMapped;
		getters.orderOfDepartments.forEach( (department) => {
			map[department].partOrder.forEach( partId => {
				map[department].parts[partId].goals.forEach( goal => {					
					items.push({
						department: department, 
						part: partId, 
						goal: goal, 
						uniqueId: `${department}-${partId}-${goal}`
					})
				})
			})
		})
		return items;
	},
	departmentSections(state){
		var sections =  {}
		if( !state.departments ){
			return;
		}
		Object.keys(state.departments).forEach( d => {
			sections[d] = state.departments[d].section
		}  )
		return sections;
	},
	departmentCompletionRates(state, getters){
		var departments = {...getters.departmentSections};
		var rates = getters.completionRatesFlat;
		Object.keys(departments).forEach( department => {
			var values = rates.filter( a => a.department == department );
			var sum  = values.reduce(( sum, value ) => {
				return sum + (value.completion || 0);
			}, 0 )
			var count = values.length;
			departments[department] = sum / count;
		})
		return departments
	},
	completionRatesFlat(state, getters ){
		var flat = getters.igMappedFlat;
		var assessment = state.assessment;
		var questions = state.questions;
		var sections = getters.departmentSections;
		var cr = flat.map( m => {
			var section = sections[m.department]
			var data = assessment.sections[section].data;
			if( data ){			
				var response = Object.values(data).find( a =>  {
					return a.part == m.part &&
					a.goal == m.goal
				})
				if( response ){
					var visibleQuestions = Object.values(questions).filter( a => mwsurveyutilities.visibilityTest(a, response.data) ).map( q => q.id );
					var answeredQuestions = Object.keys(response.data).filter( a => response.data[a]);
					m.data = response.data 
					m.completion = answeredQuestions.length/visibleQuestions.length
				}
				else{
					m.completion = 0;
					m.data = [];
				}
			}
			return m;
		} );
		return cr;
	},
	questionArray(state){
		var questions = state.questions;
		var order = mwutilities.getPositions(questions);
		return order.map( o => {
			var q = questions[o];
			q.id = o;
			return q;
		})

	}

}

