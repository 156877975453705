import Vue from 'vue'
import firebase from 'firebase';

const state = {};

const actions = {
  fetchAll({commit}){
    firebase.firestore().collection("departments").where("status", "!=", "deleted").get().then( snap => {
      snap.forEach( doc => {
        var data = doc.data();
        data.id = doc.id;
        commit("SET", {what: doc.id, value: data })
      })
    })
  }, 
  fetchOne({commit}, id){
    var subscription = firebase.firestore().doc("departments/" + id).onSnapshot( doc => {
        commit("SET", {what: doc.id, value: doc.data() })
    })
    commit("SUBSCRIBE", {subscription, id: id}, {root: true})
  }, 
  new({commit, dispatch}, data ){
    return new Promise( (resolve, reject) => {
      dispatch( "addDoc", {
        collection: "departments", 
        data: data
      }, {root: true}).then( id => {
        data.id = id;
        commit("SET", {what: id, value: data });
        resolve(id)
      }).catch( e => reject(new Error( e ) ))
    })
  }
};

const mutations = {
  SET(state, {what, value} ) {
    Vue.set(state, what, value )
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
