import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)

import actions from "./actions.js";
import getters from "./getters.js";
import auth from "../store/modules/Auth.js"
import user from "../store/modules/User.js"
import collaborations from "../store/modules/Collaborations.js"
import questions from "../store/modules/user/Questions.js"


export default new Vuex.Store({
	state: {
		subscriptions: {}, 
		currentGoal: false, 
		currentPart: false, 
		currentDepartment: false,
		goals: {},
		goalGroups: {},
		departments: {},
		questions: {}

	},
	mutations: {
		GO_HOME(state){
			Vue.set( state, "currentDepartment", false );
			Vue.set( state, "currentPart", false );
			Vue.set( state, "currentGoal", false );
		},
		GO_TO(state, next ){
			if( next.part ){
				Vue.set( state, "currentPart", next.part );
			}
			if( next.goal ){
				Vue.set( state, "currentGoal", next.goal );
			}
			if( next.department ){
				Vue.set( state, "currentDepartment", next.department );
			}
		},
		SET( state, {collection, id, data } ){
			Vue.set(state[collection], id, data )
		}, 
		RESET(){
			console.log("blah")
		},
		SET_VALUE(state, {key, value} ){
			Vue.set( state, key, value )
		},
		SUBSCRIBE(state, {id, subscription}){
			// storing the subscriptions so they can be removed
			Vue.set( state.subscriptions, id, subscription )
		},
		DELETE( state, {where, what}){
			where = where.split("/");
			var from = state;
			where.forEach( key => {
				from = from[key];
			})
			Vue.delete(from, what )
		}
	},
	getters: getters,
	actions: actions,
	modules: {
		auth, user, collaborations, questions
	}
})
