import Vue from 'vue'
import firebase from 'firebase';

const state = {};

const actions = {
  fetchAll({commit}){
    firebase.firestore().collection("questions").where("status", "!=", "deleted").get().then( snap => {
      snap.forEach( doc => {
        var data = doc.data();
        data.id = doc.id;
        commit("SET", {what: doc.id, value: data })
      })
    })
  }, 
  fetchOne({commit}, id){
    var subscription = firebase.firestore().doc("questions/" + id).onSnapshot( doc => {
        commit("SET", {what: doc.id, value: doc.data() })
    })
    commit("SUBSCRIBE", {subscription, id: id}, {root: true})
  },
  fetchAnswerOptions({commit}, question ){
    if( !question ){
      return;
    }
    var subscription =  firebase.firestore().collection("questions/" + question + "/answerOptions").where("status", "!=", "deleted").onSnapshot( snap => {
      snap.forEach( doc => {
        var data = doc.data()
        commit("SET_ANSWER_OPTION", {question: question, id: doc.id,  data })
      } );
    })
    commit("SUBSCRIBE", {subscription, id: question + "-answers"}, {root: true})
  },
};

const mutations = {
  RESET(state){
    Object.keys(state).forEach( key => {
      Vue.set( state, key, null )
    })
  },
  SET(state, {what, value} ) {
    Vue.set(state, what, value )
  },
  SET_ANSWER_OPTION( state,  {question, id, data} ){
    if( !state[question].answerOptions ){
      Vue.set(state[question], "answerOptions", {} )
    }
    Vue.set(state[question].answerOptions, id, data )
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
