const state = {};

const actions = {
  fetchOne({dispatch}, id){
    dispatch("fetchOne", {collection: "users", id: id }, {root: true})
  }, 
};

const mutations = {};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
