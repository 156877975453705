import Vue from "vue";
import firebase from "firebase";

const state = {};

const actions = {
  fetchAll({dispatch, commit}){
    firebase.firestore().collection("goals").where("status", "==", "published" ).get().then( snap => {
      snap.forEach( doc => {
        commit( "SET", {id: doc.id, data: doc.data() })
      })
    })
    dispatch( "fetchAssessmentSpecificGoals" );
  }, 
  fetchOne({dispatch}, id){
    dispatch("fetchOne", {collection: "goals", id: id }, {root: true})
  },
  fetchAssessmentSpecificGoals({rootState, commit}){
    var id = rootState.assessment.id;
    if(!id){
      return;
    }
    firebase.firestore().collection("goals").where("status", "==", "pending" ).where("assessment", "==", id ).get().then( snap => {
      snap.forEach( doc => {
        commit( "SET", {id: doc.id, data: doc.data() })
      })
    })

  },
  new({dispatch, rootState, commit}, data ){
    return new Promise( (resolve) => {
      data.author = rootState.user.id;
      data.status = "pending";
      data.assessment = rootState.assessment.id;
      dispatch("addDoc", {
        collection: "goals",
        data: data 
      }, { root: true }
      ).then( id => {
        commit("SET", {data, id })
        return resolve( id )
      })
    })
  } 
};


const mutations = {
  SET( state, {data, id } ){
    Vue.set( state, id, data )
  },
  RESET(state){
    Object.keys(state).forEach( key => {
      Vue.set( state, key, null )
    })
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
