<template>
	<v-list-group  color="igblack" v-model="active" :class="{'active-section': isCurrent}" dense no-action sub-group>
		<template v-slot:activator>
			<v-tooltip bottom>
				<template v-slot:activator="{on}">
					<v-list-item-title v-on="on">
						{{name}}
							<CompletionRateBadge :part="id" :department="department" :goal="false"></CompletionRateBadge>
							<PollChip class="ml-2" :department="department" :goal="false" :goalGroup="goalGroup"></PollChip>

					</v-list-item-title>
				</template>
				{{goalGroupDescription}}
			</v-tooltip>
		</template>
		<GoalNavigation v-for="goal in goals" :key="goal" :id="goal" :part="id" :department="department"></GoalNavigation>
		<v-dialog v-model="dialog" :width="mwstylesettings.MediumDialogWidth">
			<template v-slot:activator="{on}">
				<v-list-item dense v-on="on">
					<v-list-item-title>{{mwtr.NewGoalNavItem}}</v-list-item-title>
					<v-list-item-icon>
						<v-icon color="green">mdi-plus-circle</v-icon>
					</v-list-item-icon>
				</v-list-item>									
			</template>
			<NewGoal :exclude="excludeGoals" :hidden="config.hiddenGoals" @new="handleNewGoal"></NewGoal>
		</v-dialog>
	</v-list-group>
</template>

<style lang="less">
	.v-list-item__title {
		padding-right: 15px;
	}
</style>

<script type="text/javascript">
	import Vuex from "vuex";
	import NewGoal from "@/components/user/assessment-config/NewGoal"
	import GoalNavigation from "@/components/navigation/GoalNavigation"
	import CompletionRateBadge from "@/components/navigation/CompletionRateBadge"
	import PollChip from "@/components/navigation/PollChip"

	export default {
		name: "PartNavigation", 
		props: {
			activeParent: {},
			id: {type: String },
			name: {type: String },
			department: {type: String },
			goalGroup: {type: String},
			section: {type: String },
			goals: {type: Array, default: () => [] }
		},
		data: () => {
			return {
				dialog: false,
				active: false
			}
		},
		components: {
			NewGoal,
			CompletionRateBadge,
			GoalNavigation,
			PollChip
		},
		computed: {
			...Vuex.mapState({
				assessment: state => state.assessment,
				current: state => state.currentPart,
				goal: state => state.currentGoal,
				organisation: state => state.organisation,
			}), 
			goalGroupDescription(){
				return this.$store.state.goalGroups[this.goalGroup].description;
			},
			config(){
				return this.assessment.config || {}
			},
			isCurrent(){
				return this.current == this.id;
			},
			excludeGoals(){
				var section = this.$store.getters.igMapped[this.department];
				if( !section ){
					return
				}
				return Object.values(section.parts).flatMap( b => b.goals )
			},			
		},
		methods: {
			handleNewGoal(goal){
				var config = this.config;
				config.sections[this.department][this.id].push( goal );
				this.$store.dispatch("updateDoc",  {
					doc: `organisations/${this.organisation.id}`,
					data: {assessmentConfig: config }
				}).then( () => {
					this.dialog = false
				})
			}
		},
		watch: {
			active(value){
				if( value ){
					this.$emit("opened");
				}
			},
			activeParent(value){
				if( value !== this.id ){
					this.active = false;
				}
			},
			isCurrent: {
				immediate: true, 
				handler(value){
					if( value ){
						this.active = true;
					}
				}
			},
			goal(){
				if( this.active == false && this.current == this.id ){
					this.active = true;
				}
			}
		}
		// created(){
			
		// }

	}
// </script>"