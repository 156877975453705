const state = {};

const actions = {
  fetchAll({dispatch}){
    dispatch("fetchAll", "goalGroups", {root: true })
  }, 
  fetchOne({dispatch}, id){
    dispatch("fetchOne", {collection: "goalGroups", id: id }, {root: true})
  }, 
  new({dispatch}, data ){
    return dispatch("new", {collection: "goalGroups", data: data }, {root: true})
  }
};

const mutations = {
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
