<template>
	<v-chip dark x-small :color="color" v-if="text">
		{{text}}
	</v-chip>
</template>

<style lang="less">
	
</style>

<script type="text/javascript">
	import Vuex from "vuex";
	// import - from "@/components/"
	export default {
		name: "", 
		props: {
			department: {}
		},
		data: () => {
			return {

			}
		},
		// components: {

		// },
		computed: {
			...Vuex.mapState({
				assessment: state => state.assessment || {},
			}),
			map(){
				return this.$store.getters.igMapped[this.department]
				// return this.assessment.sections[this.section] || {}
			}, 
			color(){
				if(  this.map.status == "delegated" ){
					return "primary"
				}
				if(  this.map.status == "complete" ){
					return "green"
				}
				return false;
			},
			text(){
				if(  this.map.status == "delegated" ){
					return this.mwtr.Delegated
				}
				if(  this.map.status == "complete" ){
					return this.mwtr.Complete
				}
				return false;
			},
		},
		// methods: {
		// }
		// created(){
			
		// }

	}
// </script>"