import store from "@/store"
import firebase from "firebase"
export default {
	checkAuth(){
		return new Promise( (resolve, reject) => {
			firebase.auth().onAuthStateChanged( (user) => {
				if( user ){
					store.dispatch("user/getRole", user.uid ).then( role => {
						return resolve( role )
					})
				}
				else{
					return reject( )
				}
			})
		})
	},

} 