<template>
<v-list-group :value="expanded" dense> 
	<template v-slot:activator>
		<v-list-item dense class="pl-0">
			<v-list-item-title>{{mwtr.AssessmentConfigNavItem}}</v-list-item-title>
		</v-list-item>
	</template>
	<v-list-item class="pl-8" v-for="(link, i) in links" :key="i" dense :to="link.to">
		<v-list-item-content>
			<v-list-item-title>{{link.text}}</v-list-item-title>
		</v-list-item-content>
	</v-list-item>
	<AcceptDefaultConfig></AcceptDefaultConfig>
</v-list-group>
</template>

<style lang="less">
	.v-list-item.no-padding {
		padding-left: 0px;
	}
</style>

<script type="text/javascript">
	// import Vuex from "vuex";
	import AcceptDefaultConfig from "@/components/user/assessment-config/AcceptDefaultConfig"
	export default {
		name: "ConfigNavigation", 
		props: {
			
		},
		data: () => {
			return {
			}
		},
		components: {
			AcceptDefaultConfig
		},
		computed: {
			links(){
				return [
					{
						text: this.mwtr.ConfigOverview,
						to:  "/assessment-config/overview"
					},
					{
						text: this.mwtr.DepartmentConfig,
						to:  "/assessment-config/departments"
					},
					{
						text: this.mwtr.GoalConfig,
						to:  "/assessment-config/sections"
					}
				]
			},
			// ...Vuex.mapState({})
			expanded(){
				return this.$route.meta.in == "assessment-config"
			}
		},
		// methods: {
		// }
		// created(){
			
		// }

	}
// </script>"