<template>
	<v-dialog v-model="dialog" :width="mwstylesettings.SmallDialogWidth">
		<template v-slot:activator="{on}">
			<v-btn icon v-on="on" class="ml-2">
				<v-icon>mdi-delete</v-icon>
			</v-btn>
		</template>
		<v-card>
			<v-card-title>{{mwtr.AreYouSure}}</v-card-title>
			<v-card-text>
				Möchten Sie dieses Ziel aus Ihrer Umfrage löschen? 
			</v-card-text>
			<v-card-actions>
				<v-spacer></v-spacer>
				<v-btn text @click="dialog = false">{{mwtr.cButton}}</v-btn>
				<v-btn color="primary" @click="deleteGoal">{{mwtr.DeleteButton}}</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<style lang="less">
	
</style>

<script type="text/javascript">
	import Vuex from "vuex"
	// import - from "@/components/"
	export default {
		name: "DeleteGoal", 
		props: {
			goal: { type: String},
			part: { type: String},
			department: { type: String},
		},
		data: () => {
			return {
				dialog: false,
			}
		},
		computed: {
			...Vuex.mapState({			
				current: state => state.currentGoal,
				assessment: state => state.assessment,
			}), 
			config(){
				return this.assessment.config;
			},
		},
		methods: {
			deleteGoal(){
				var self = this;
				var config = self.config;
				var next = this.$store.getters.getNext ?  {...this.$store.getters.getNext } : false;
				var found = config.sections[self.department][self.part].indexOf( self.goal )
				config.sections[self.department][self.part].splice( found, 1 );
				self.$store.dispatch("assessment/saveConfig", config ).then( () => {
					self.dialog = false
					if( self.current == self.goal ){
						if( next ){
							self.$store.commit("GO_TO", next );
						}
						else{
							self.$store.commit("GO_HOME");
						}
					}
				})
			}
		}

		// created(){
			
		// }

	}
// </script>"