<template>
	<span>
		<v-btn small dark @click="logout" color="igdark" class="ml-2">{{mwtr.Logout}}</v-btn>
		<AutomaticLogout></AutomaticLogout>
	</span>
</template>

<style lang="less">
	
</style>

<script type="text/javascript">
	import AutomaticLogout from "@/components/auth/AutomaticLogout"
	export default {
		name: "SignOut",
		props: {
			
		},
		data: () => {
			return {

			}
		},
		components: {
			AutomaticLogout
		},
		computed: {
		},
		methods: {
			logout(){
				const self = this;
				self.$store.dispatch("auth/logout").then( () => {
					if( self.$route.path !== "/" ){
						self.$router.replace("/")
					}
				})
			}
		},

	}
</script>"