<template>
	<v-list-group color="igblack" :value="isCurrent" v-if="display">
		<template v-slot:activator> 		
			<v-list-item>
				
				<v-list-item-avatar>
					<SectionCompleteButtons :department="department"></SectionCompleteButtons>
				</v-list-item-avatar>
				<v-list-item-content>					
					<v-list-item-title>
						{{name}}
					</v-list-item-title>
					<v-list-item-subtitle>
						<CompletionRateBadge :part="false" :department="department" :goal="false"></CompletionRateBadge>
						<StatusChip class="ml-2" :department="department"></StatusChip>
						<PollChip class="ml-2" :department="department" :goal="false" :goalGroup="false"></PollChip>
							
					</v-list-item-subtitle>
				</v-list-item-content>
			</v-list-item>
		</template>
		<PartNavigation v-for="id in partOrder" :key="id" :id="id" v-bind="parts[id]" :department="department" :section="section" @opened="activePart = id" :activeParent="activePart"></PartNavigation>
	</v-list-group>
</template>

<style lang="less">
	.v-list-item__title, .v-list-item__subtitle {
		text-overflow: unset;
		white-space: break-spaces;
    }
</style>

<style lang="less" scoped>
	.v-list-item {
		padding-left: 0;
	}
</style>

<script type="text/javascript">
	import Vuex from "vuex";
	import PartNavigation from "@/components/navigation/PartNavigation"
	import SectionCompleteButtons from "@/components/navigation/SectionCompleteButtons"
	import StatusChip from "@/components/navigation/StatusChip"
	import PollChip from "@/components/navigation/PollChip"
	import CompletionRateBadge from "@/components/navigation/CompletionRateBadge"
	export default {
		name: "SectionNavigation", 
		props: {
			partOrder: {},
			department: {},
			section: {},
			name: {},
			parts: { type: Object, default: () => ({ }) }
		},
		data: () => {
			return {
				activePart: false
			}
		},
		components: {
			StatusChip,
			PartNavigation,
			SectionCompleteButtons,
			CompletionRateBadge,
			PollChip
		},
		computed: {
			...Vuex.mapState({
				current: state => state.currentDepartment,
				currentPart: state => state.currentPart,
			}),
			isCurrent(){
				return this.current == this.department
			}, 
			count(){
				var count = [];
				Object.values(this.parts).forEach( a => count = count.concat(a.goals))
				return count.length
			},	
			display(){
				return this.department && this.count > 0
			}
		},
	

	}
// </script>"