<template>
<v-dialog v-model="dialog" :width="mwstylesettings.SmallDialogWidth">
	<template v-slot:activator="{ on }">
		<v-btn v-if="button == 'icon'" :color="disabled ? 'grey' : 'green'" icon v-on="on">
			<v-icon>mdi-check-circle</v-icon>
		</v-btn>
		<v-btn v-else icon block :disabled="disabled" color="igblack" v-on="on">{{mwtr.CompleteButton}}</v-btn>
	</template>
	<v-card>
		<v-card-title></v-card-title>
		<v-card-text>
			<p v-if="disabled">{{mwtr.CompleteAllQuestionsMessage}}</p>
			<p v-else>{{mwtr.AreYouSure}}</p>
		</v-card-text>
		<v-card-actions>
			<v-spacer></v-spacer>
			<v-btn @click="dialog = false" text v-if="disabled">{{mwtr.BackButton}}</v-btn>
			<v-btn @click="save" color="primary" v-else>{{mwtr.SaveButton}}</v-btn>
		</v-card-actions>
	</v-card>
</v-dialog>
</template>

<style lang="less">
	
</style>

<script type="text/javascript">
	// import - from "@/components/"
	export default {
		name: "Complete", 
		props: {
			id: {},
			disabled: {type: Boolean, default: false },
			button: {type: String, default: "block"}
		},
		data: () => {
			return {
				dialog: false,
			}
		},
		// components: {

		// },
		computed: {
		},
		methods: {
			save(){
				var id = this.id;
				this.$store.dispatch("assessment/saveSectionStatus", {
					id: id, 
					status: "complete"
				})
				this.dialog = false;
			}
		}
		// created(){
			
		// }

	}
// </script>"