<template>
	<v-card>
		<v-card-title></v-card-title>
		<v-card-text>
			<v-combobox ref="combobox" v-model="newGoal" @keydown.delete="clear" @keydown="checkNew" :items="items" :label="mwtr.NewGoalNavItem" :hint="mwtr.ComboboxHint" permanent-hint></v-combobox>
			<v-textarea v-if="isNew" :label="mwtr.DescriptionLabel">
			</v-textarea>
			<!-- <v-autocomplete v-if="isNew" multiple v-model="selectTags" chips removable-chips :items="tagItems" item-value="value" item-text="text" label="Tags"></v-autocomplete> -->
		</v-card-text>
		<v-card-actions>
			<v-spacer></v-spacer>
			<v-btn color="primary" @click="saveNewGoal" :disabled="!newGoal">{{mwtr.SaveButton}}</v-btn>
		</v-card-actions>
	</v-card>
</template>

<style lang="less">
	
</style>

<script type="text/javascript">
	import Vuex from "vuex";
	// import - from "@/components/"
	export default {
		name: "NewGoal", 
		props: {
			exclude: {},
			hidden: {default: () => ([])}
		},
		data: () => {
			return {
				description: "",
				newGoal: "", 
				selectTags: []
			}
		},
		// components: {

		// },
		computed: {
			...Vuex.mapState({
				tags: state => state.tags
			}),
			items() {
				var self = this;
				var goals = self.$store.getters.activeGoals
				var exclude = self.exclude.filter( a => self.hidden.indexOf(a) < 0 ) 
				return goals.filter( g => {
					return exclude.indexOf( g.value ) < 0
				})
			},
			tagItems(){
				return this.mwutilities.itemsArray(this.tags)
			},
			isNew(){
				return this.newGoal.length > 0 && typeof this.newGoal == "string"
			}
		},
		methods: {
			checkNew(){
				if( this.newGoal && !this.isNew ){
					var textValue = this.$refs.combobox.lazySearch;
					var selected = this.newGoal.text; 
					if( textValue !== selected ){
						this.newGoal = textValue;
					}
				}
			},
			clear(){
				this.newGoal  = "";
				this.selectTags = [];
				this.description = "";
			},
			saveNewGoal(){
				const self = this;
				if( self.isNew ){
					var data = {
						name: self.newGoal, 
						mouseover: self.description, 
						tags: self.tags, 
					}
					self.$store.dispatch("goals/new", data ).then( id => {
						if( id ){
							self.$emit("new", id);
						}
						self.clear();
					})
				}
				else{
					if( self.newGoal.value ){
						self.$emit("new", self.newGoal.value);
					}
					self.clear();
				}
			}
		}

		// created(){
			
		// }

	}
// </script>"