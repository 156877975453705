import Vue from 'vue'
import firebase from 'firebase';

let getEmpty = () => {
	return {	
		id: false, 
		assessment: false,
		assessmentConfig: false,
		name: "",
		owner: "",
		status: "",
		published: "",
		address: "",
		postcode: "",
		streetAddress: "",
		state: "",
		city: "",
		departments: [],
		universityHospital: "",
		type: "",
		created: "",
		beds: "",
		users: ""
	}
};


const state = getEmpty()


const actions = {
	reset({commit}){
		commit("RESET" )
	},
	fetch({commit, state } ){
		var id = state.id;
		var subscription = firebase.firestore()
		.doc(`organisations/${state.id}` )
		.onSnapshot( doc => {
			if( !doc.exists ){
				return;
			}
			var data = doc.data();
			commit("SET", data )
		})
		commit("SUBSCRIBE", {subscription, id: id }, {root: true})
	},
	start({commit, dispatch}, id){
		commit("SET", {id: id})
		dispatch("fetch");
	},	
	update({state, dispatch, commit}, data){
		dispatch("updateDoc", {
			doc: "organisations/" + state.id,
			data: data
		},  {root: true});
		commit("SET", data)
	}
};

const mutations = {
	SET(state, values ){
		for( var key in values ){
			if( typeof state[key] !== "undefined" ){
				Vue.set( state, key, values[key] )
			}
		}
	},
	RESET(state){
		Object.assign(state, getEmpty())
	},
	SET_SECTIONS(state, {id, data}){
		Vue.set( state.sections, id, data )
	},
	SET_SECTION_VALUE(state, {id, key, value}){
		Vue.set( state.sections[id], key, value )
	},
	SET_SECTION_DATA(state, {section, id, data}){
		if( !state.sections[section].data ){
			Vue.set(state.sections[section], "data", {} )
		}
		Vue.set( state.sections[section].data, id, data )
	}
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
