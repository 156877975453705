import Vue from "vue";

const state = {};

const actions = {
  fetchAll({dispatch}){
    dispatch("fetchAll", "goalGroups", {root: true })
  }, 
  fetchOne({dispatch}, id){
    dispatch("fetchOne", {collection: "goalGroups", id: id }, {root: true})
  }, 
};

const mutations = {
  RESET(state){
    Object.keys(state).forEach( key => {
      Vue.set( state, key, null )
    })
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
