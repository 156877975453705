<template>
	<v-sheet color="transparent" flat class="ma-4 mr-5">
		<div style="margin-top: 60px" id="firebaseui-auth-container"></div>
	</v-sheet>
</template>

<style lang="less">
	@import "../../assets/css/variables.less";
	@import "../../../node_modules/firebaseui/dist/firebaseui.css";

	.firebaseui-container{
		font-family: @ig-font-family !important;
	}
	.firebaseui-button, .mdl-button--raised.mdl-button--colored{
		color: white !important;
		background-color: @ig-black !important;
	}
</style>

<script type="text/javascript">
	import firebase from 'firebase';
	import * as firebaseui from 'firebaseui'
	export default {
		name: "Auth", 
		mounted() {
			const self = this;
			var uiConfig = {
				callbacks: {
					signInSuccessWithAuthResult: function(authResult){
						var user = authResult.user;
						var isNewUser = authResult.additionalUserInfo.isNewUser;
						if( isNewUser ){
							self.$store.dispatch("auth/registered", user );
						}
						else{
							self.$store.commit("user/SET", {what: "uid", value: user.uid })
							self.$store.dispatch("auth/loggedIn", user );
						}
					},
				},
				signInOptions: [
					{
						provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
					},
				],
			};
			var ui = firebaseui.auth.AuthUI.getInstance() || new firebaseui.auth.AuthUI(firebase.auth());
			ui.start("#firebaseui-auth-container", uiConfig);
		}
	}
</script>