<template>
<v-bottom-sheet v-model="sheet">
	<v-sheet height="200px" >
		<v-app-bar flat>
			<v-spacer></v-spacer>
			<v-btn icon @click="sheet = false"><v-icon>mdi-close</v-icon></v-btn>
		</v-app-bar>
		<v-row>
			<v-col cols="4">
				
			</v-col>
		</v-row>
	</v-sheet>
</v-bottom-sheet>
</template>

<style lang="less">
	
</style>

<script type="text/javascript">
	// import Vuex from "vuex";
	// import - from "@/components/"
	export default {
		name: "BottomNavigation", 
		props: {
			show: {} 
		},
		data: () => {
			return {
				sheet: false
			}
		},
		watch: {
			show: {
				immediate: true, 
				handler(){
					this.sheet = this.show
				}
			}, 
			sheet(){
				if( this.show && !this.sheet ){
					this.$emit("hidden")
				}
			}
		},
		// components: {

		// },
		// methods: {
		// }
		// created(){
			
		// }

	}
// </script>"