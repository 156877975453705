import Vue from 'vue'
import firebase from 'firebase';

const state = {};

const actions = {
  fetchAll({commit}){
    firebase.firestore().collection("questions").where("status", "!=", "deleted").get().then( snap => {
      snap.forEach( doc => {
        var data = doc.data();
        data.id = doc.id;
        commit("SET", {what: doc.id, value: data })
      })
    })
  }, 
  fetchOne({commit}, id){
    var subscription = firebase.firestore().doc("questions/" + id).onSnapshot( doc => {
      for( var key in doc.data() ){
        commit("SET_QUESTION", {id: doc.id, key: key, value: doc.data()[key] })
      }
    })
    commit("SUBSCRIBE", {subscription, id: id}, {root: true})
  }, 
  new({commit, dispatch}, data ){
    return new Promise( (resolve, reject) => {
      dispatch( "addDoc", {
        collection: "questions", 
        data: data
      }, {root: true}).then( id => {
        data.id = id;
        commit("SET", {what: id, value: data });
        resolve(id)
      }).catch( e => reject(new Error( e ) ))
    })
  },
  fetchAnswerOptions({commit}, question ){
    if( !question ){
      return;
    }
    var subscription =  firebase.firestore().collection("questions/" + question + "/answerOptions").where("status", "!=", "deleted").onSnapshot( snap => {
      snap.forEach( doc => {
        var data = doc.data()
        commit("SET_ANSWER_OPTION", {question: question, id: doc.id,  data })
      } );
    })
    commit("SUBSCRIBE", {subscription, id: question + "-answers"}, {root: true})
  },
  addAnswerOption({dispatch, commit}, {question, answer} ){
    if( !question || !answer ){
      return;
    }
    dispatch; commit; 
    dispatch("addDoc", {
      data: answer,
      collection: "questions/" + question + "/answerOptions" 
    }, {root: true} )
  },


};

const mutations = {
  SET(state, {what, value} ) {
    Vue.set(state, what, value )
  },
  SET_QUESTION(state, {id, key, value } ){
    if( !state[id] ){
      Vue.set( state, id, {} )
    }
    Vue.set(state[id], key, value )
  },
  SET_ANSWER_OPTION( state,  {question, id, data} ){
    if( !state[question].answerOptions ){
      Vue.set(state[question], "answerOptions", {} )
    }
    Vue.set(state[question].answerOptions, id, data )
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
