import { render, staticRenderFns } from "./SectionNavigation.vue?vue&type=template&id=84124f06&scoped=true&"
import script from "./SectionNavigation.vue?vue&type=script&lang=js&"
export * from "./SectionNavigation.vue?vue&type=script&lang=js&"
import style0 from "./SectionNavigation.vue?vue&type=style&index=0&lang=less&"
import style1 from "./SectionNavigation.vue?vue&type=style&index=1&id=84124f06&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "84124f06",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VListGroup } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
installComponents(component, {VListGroup,VListItem,VListItemAvatar,VListItemContent,VListItemSubtitle,VListItemTitle})
