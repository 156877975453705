import Vue from "vue";
import firebase from "firebase";
const state = {};

const actions = {
  fetchAll({dispatch}){
    dispatch("fetchAll", "sections", {root: true })
  }, 
  fetchOne({commit, dispatch }, id){
    firebase.firestore().doc(`sections/${id}`).get().then((doc) => {
      commit("SET", {collection: "sections", id: id, data: doc.data() }, {root: true });
      dispatch("fetchParts", id )
    })
  },
  fetchParts({commit}, section ){
    if( !section ){
      return;
    }
    firebase.firestore().collection("sections/" + section + "/parts").where("status", "==", "published").get().then( snap => {
      snap.forEach( doc => {
        var data = doc.data()
        commit("SET_PART", {section: section, id: doc.id,  data })
      } );
    })
  },
  getDefaultConfig({state}, id ){
    var obj = {};
    var section = state[id]
    var parts = Object.keys(section.parts);
    parts.forEach( p => {
      obj[p] = section.parts[p].goals;
    })
    return obj;
  }
};

const mutations = {
  RESET(state){
    Object.keys(state).forEach( key => {
      Vue.set( state, key, null )
    })
  },
  SET_PART( state,  {section, id, data} ){
    if( !state[section].parts ){
      Vue.set(state[section], "parts", {} )
    }
    Vue.set(state[section].parts, id, data )
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
