<template>
	<v-list-item dense @click="goToPart(department, part, id)" :class="{active: isCurrent}" >
		<v-list-item-content>
			<v-list-item-title>{{goal.name}}
				<CompletionRateBadge :goal="id" :department="department" :part="part"></CompletionRateBadge>
			</v-list-item-title>
			<v-list-item-subtitle>
				<v-chip x-small v-if="custom" color="primary">{{mwtr.CustomGoal}}</v-chip>
				<PollChip class="ml-2" :department="department" :goal="id" :goalGroup="part.goalGroup"></PollChip>
			</v-list-item-subtitle>
		</v-list-item-content>
		<v-list-item-action>
			<DeleteGoal v-if="showDelete" :goal="id" :part="part" :department="department"></DeleteGoal>
		</v-list-item-action>
	</v-list-item>					
</template>

<style lang="less">
	
</style>

<script type="text/javascript">
	import Vuex from "vuex";
	import DeleteGoal from "@/components/user/assessment-config/DeleteGoal"
	import CompletionRateBadge from "@/components/navigation/CompletionRateBadge"
	import PollChip from "@/components/navigation/PollChip"
	export default {
		name: "", 
		props: {
			department: {},
			part: {}, 
			id: {}
		},
		data: () => {
			return {

			}
		},
		components: {
			CompletionRateBadge,
			PollChip,
			DeleteGoal
		},
		computed: {
			...Vuex.mapState({
				user: state => state.user.uid,
				goals: state => state.goals
			}),
			goal(){
				return this.goals[this.id] || {}
			},
			uniqueId(){
				return `${this.department}-${this.part}-${this.id}`
			},
			currentGoalUniqueId(){
				return this.$store.getters.currentGoalUniqueId;
			},
			isCurrent(){
				return this.uniqueId == this.currentGoalUniqueId
			},
			currentPart(){
				var sectionId = this.$store.getters.departmentSections[this.department];
				var section = this.$store.state.sections[sectionId];
				var part = section.parts[this.part];
				return part;
			},
			custom(){
				return !this.currentPart.goals.includes( this.id )
			},
			isLead(){
				return this.$store.getters.isCurrentlyLeadRespondent;
			},
			showDelete(){
				if( !this.custom ){
					return false;
				}
				if( !this.goal.author || this.isLead ){
					return true;
				}
				if( this.goal.author && this.goal.author == this.user ){
					return true;
				}
				return false;
			}
		},
		methods: {
			goToPart(department, part, goal){
				this.$store.commit("GO_TO", {goal, part, department})
			},
		}
		// created(){
			
		// }

	}
// </script>"