import organisations from "../store/modules/admin/Organisations.js";
import assessments from "../store/modules/admin/Assessments.js";
import users from "../store/modules/admin/Users.js";
import departments from "../store/modules/admin/Departments.js";
import questions from "../store/modules/admin/Questions.js";
import tags from "../store/modules/admin/Tags.js";
import goals from "../store/modules/admin/Goals.js";
import goalGroups from "../store/modules/admin/GoalGroups.js";
import sections from "../store/modules/admin/Sections.js";

import organisation from "../store/modules/user/Organisation.js";
import assessment from "../store/modules/user/Assessment.js";
// import userOrganisations from "../store/modules/user/Organisations.js";
import userTags from "../store/modules/user/Tags.js";
// import userQuestions from "../store/modules/user/Questions.js";
import userGoals from "../store/modules/user/Goals.js";
import userUsers from "../store/modules/user/Users.js";
import userGoalGroups from "../store/modules/user/GoalGroups.js";
import userDepartments from "../store/modules/user/Departments.js";
import userSections from "../store/modules/user/Sections.js";

export default {
	load(state, role){
		var modules = [
			{name: "organisations", module: organisations, roles: ["admin", "superadmin"]},
			{name: "assessments", module: assessments, roles: ["admin", "superadmin"]},
			{name: "users", module: users, roles: ["admin", "superadmin"]},
			{name: "departments", module: departments, roles: ["admin", "superadmin"]},
			{name: "tags", module: tags, roles: ["admin", "superadmin"]},
			{name: "questions", module: questions, roles: ["admin", "superadmin"]},
			{name: "goals", module: goals, roles: ["admin", "superadmin"]},
			{name: "sections", module: sections, roles: ["admin", "superadmin"]},
			{name: "goalGroups", module: goalGroups, roles: ["admin", "superadmin"]},

			{name: "organisation", module: organisation, roles: ["user"]},
			{name: "assessment", module: assessment, roles: ["user"]},
			{name: "sections", module: userSections, roles: ["user"]},
			// {name: "organisations", module: userOrganisations, roles: ["user"]},
			{name: "tags", module: userTags, roles: ["user"]},
			{name: "users", module: userUsers, roles: ["user"]},
			{name: "goals", module: userGoals, roles: ["user"]},
			{name: "goalGroups", module: userGoalGroups, roles: ["user"]},
			{name: "departments", module: userDepartments, roles: ["user"]},
			// {name: "questions", module: userQuestions, roles: ["user"]},
		]
		modules.forEach( m => {
			if( !this.hasModule(m.name) ){		
				if( m.roles.indexOf( role ) > -1 ){
					this.registerModule(m.name, m.module);			
				}
			}
		})
	}, 
	remove(){
		var modules = [
			"assessment",
			"departments", 
			"goalGroups", 
			"goals", 
			"organisation", 
			"organisations", 
			// "questions", 
			"sections",
			"tags", 
			"users", 
		]; 
		modules.forEach( m => {
			if( this.hasModule(m) ){			
				this.unregisterModule(m);
			}
		})
	}
}