<template>
	<v-sheet class="ig-sidebar">
		<v-component v-if="showNavigation" :is="showNavigation"></v-component>
	</v-sheet>
</template>

<style lang="less">
	.ig-sidebar {
		margin-top: 60px;
	}
</style>

<script type="text/javascript">
	import Vuex from "vuex";
	import Login from "@/components/auth/Login"
	import AdminNavigation from "@/components/navigation/AdminNavigation/"
	import UserNavigation from "@/components/navigation/UserNavigation/"
	import ConfigNavigation from "@/components/navigation/ConfigNavigation/"
	import AssessmentNavigation from "@/components/navigation//AssessmentNavigation/"

	export default {
		name: "SidebarNavigation", 
		props: {
			
		},
		data: () => {
			return {

			}
		},
		components: {
			AdminNavigation,
			UserNavigation,
			ConfigNavigation,
			AssessmentNavigation,
			Login
		},
		computed: {
			...Vuex.mapState({
				user: state => state.user,
				auth: state => state.auth
			}),
			currentOrganisation(){
				return this.$store.getters.currentOrganisation
			},
			showNavigation(){
				if( !this.auth.logged_in ){
					return "Login"
				}
				if( this.user.role == "admin" || this.user.role == "superadmin" ){
					return "AdminNavigation"
				}
				if( this.$route.meta.in == "assessment" ){
					return "AssessmentNavigation"
				}
				if( this.$route.meta.in == "assessment-config" ){
					return "ConfigNavigation"
				}
				if( this.user.role == "user" && this.currentOrganisation ){
					return "UserNavigation"
				}
				return false;
			}
		},
		// methods: {
		// }
		// created(){
			
		// }

	}
// </script>"